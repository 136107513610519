import axios from "axios";
import React, { useEffect, useState } from "react";
import { Helmet } from "react-helmet";
import { Link, useParams } from "react-router-dom";
import { toast } from "react-toastify";
import { SITETITLE } from "../constants";

const DepartmentList = () => {
  const { departmentNameSlugUrl } = useParams();
  const [departmentDetails, setDepartmentData] = useState([]);

  const formData = new FormData();
  formData.append("departmentNameSlugUrl", departmentNameSlugUrl);

  useEffect(()=> {   
    getSubjectDetailsData();
  },[]);

  const getSubjectDetailsData = async () => {
    await axios.post(window.apiBaseUrl+'/home/top-department-courses', formData, {
      headers: { "Content-Type": "multipart/form-data" }
    })
    .then((res) => {
      if(res.status === 200){
        setDepartmentData(res.data.data);        
      } else {        
        toast.warn(res.data.message, {          
          autoClose: 5000,          
          theme: "colored",
        });
      }
    })
    .catch((err) => {       
      toast.error(err.response.data.message, {        
        autoClose: 5000,        
        theme: "colored",
        });
    }); 
  };

  return (
    <>
      <Helmet>          
          <title>{SITETITLE} :: Top Department Courses</title>          
          <meta name='description' content="" />
          <meta name='keywords' content="" />
      </Helmet>
      <div
        className="section page-banner-section bg-color-1 top-department-courses-banner"
        style={{
          backgroundImage: `url(
            "https://www.matriyeacademy.com/website-assets/images/top-department-courses-banner.webp"
          )`,
        }}
      >
        <div className="container">
          <div className="page-banner-content">
            <h1 className="title">Top department courses</h1>
          </div>
        </div>
      </div>
      <div className="section section-padding">
        <div className="container">
          <div className="course-list-wrapper">
            <div className="row">
              <div className="col-lg-12">
                <div className="tab-content">
                  <div className="tab-pane fade show active" id="grid">
                  {Array.isArray(departmentDetails)?(
                    <div className="row">
                      {departmentDetails.map((department, index) => (                
                      <div key={index} className="col-lg-3 col-sm-6">
                        <div className="single-course">
                          <div className="courses-image">
                            <div className="course-tag">
                              <p>{department.categoryName}</p>
                            </div>
                            <Link to="/filter">
                              <img
                                src={department.courseImage}
                                alt={department.courseId}
                                loading="lazy"
                              />
                            </Link>
                          </div>
                          <div className="courses-content">
                            <p className="course_department">{department.departmentName}</p>
                            <h3 className="title">
                              <Link to="/filter">{department.courseName}</Link>
                            </h3>
                          </div>
                        </div>
                      </div>
                      ))}
                    </div>
                    ):''}
                  </div>
                </div>

                {Array.isArray(departmentDetails)?(
                <div className="page-pagination">
                  <ul className="pagination justify-content-center">
                    <li>
                      <a href="/">
                        <i className="fa fa-angle-left"></i>
                      </a>
                    </li>
                    <li>
                      <a href="/">1</a>
                    </li>
                    <li>
                      <a className="active" href="/">
                        2
                      </a>
                    </li>
                    <li>
                      <a href="/">3</a>
                    </li>
                    <li>
                      <a href="/">
                        <i className="fa fa-angle-right"></i>
                      </a>
                    </li>
                  </ul>
                </div>
                ):''}
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};
export default DepartmentList;
