import React, { Component } from "react";

export default class UserSidebarComponent extends Component {
  render() {
    return (
      <>
        <div className="side-bar-left">
          <div className="main-sidebar-loggedin">
            <div className="app-sidebar__user">
              <div className="dropdown user-pro-body text-center">
                <div className="user-pic">
                  <img
                    src="https://t-tacdmy.matriyeacademy.com/apis/FileServer/profilePicture/63fc8ee1bb7dd.jpg"
                    className="ssl"
                    loading="lazy"
                    alt="user-img"
                  />
                </div>
                <div className="user-info">
                  <h6 className=" mb-0 text-dark">Riyaz Sayyed</h6>
                  <span className="text-muted app-sidebar__user-name text-sm">
                    Student
                  </span>
                </div>
              </div>
            </div>
          </div>
          <div className="sidebar-navs">
            <ul className="nav  nav-pills-circle">
              <li
                className="nav-item"
                data-toggle="tooltip"
                data-placement="top"
                title=""
                data-bs-original-title="Followers"
              >
                <a className="nav-link text-center m-2" href="/">
                  <i className="fe fe-user"></i>
                </a>
              </li>
              <li
                className="nav-item"
                data-toggle="tooltip"
                data-placement="top"
                title=""
                data-bs-original-title="Chat"
              >
                <a className="nav-link text-center m-2" href="/">
                  <i className="fe fe-mail"></i>
                </a>
              </li>
              <li
                className="nav-item"
                data-toggle="tooltip"
                data-placement="top"
                title=""
                data-bs-original-title="Settings"
              >
                <a className="nav-link text-center m-2" href="/">
                  <i className="fe fe-settings"></i>
                </a>
              </li>
              <li
                className="nav-item"
                data-toggle="tooltip"
                data-placement="top"
                title=""
                data-bs-original-title="Logout"
              >
                <a
                  href="http://localhost/matriyeacademy/logout"
                  className="nav-link text-center m-2"
                >
                  <i className="fe fe-power"></i>
                </a>
              </li>
            </ul>
          </div>

          <div className="main-sidebar-body">
            <ul className="side-menu ">
              <li className="slide">
                <a
                  className="side-menu__item Lidashboard active"
                  href="http://localhost/matriyeacademy/user/dashboard"
                >
                  <span className="side-menu__label">
                    <i className="side-menu__icon fe fe-airplay"></i> Dashboard
                  </span>
                </a>
              </li>
              <li className="slide">
                <a
                  className="side-menu__item Liprofile"
                  href="http://localhost/matriyeacademy/user/my-profile"
                >
                  <i className="side-menu__icon fe fe-user"></i>
                  <span className="side-menu__label">Profile</span>
                </a>
              </li>
              <li className="slide">
                <a
                  className="side-menu__item Lidocuments"
                  href="http://localhost/matriyeacademy/user/documents"
                >
                  <i className="side-menu__icon fe fe-file"></i>
                  <span className="side-menu__label">Document</span>
                </a>
              </li>
              <li className="slide">
                <a
                  className="side-menu__item Limycourses"
                  href="http://localhost/matriyeacademy/user/user-courses"
                >
                  <span className="side-menu__label">
                    <i className="side-menu__icon fe fe-codepen"></i>My Courses
                  </span>
                </a>
              </li>
              <li className="slide">
                <a
                  className="side-menu__item Lipaymenthistory"
                  href="http://localhost/matriyeacademy/user/purchase-history"
                >
                  <span className="side-menu__label">
                    <i className="side-menu__icon fe fe-bookmark"></i>Payment
                    History
                  </span>
                </a>
              </li>
              <li className="slide">
                <a
                  className="side-menu__item Linotification"
                  href="http://localhost/matriyeacademy/user/notifications"
                >
                  <i className="side-menu__icon fe fe-bell"></i>
                  <span className="side-menu__label">Notifications</span>
                </a>
              </li>
              <li className="slide">
                <a
                  className="side-menu__item Lileave"
                  href="http://localhost/matriyeacademy/user/leave-application"
                >
                  <i className="side-menu__icon fe fe-check-square"></i>
                  <span className="side-menu__label">Apply for leave</span>
                </a>
              </li>
            </ul>
          </div>
        </div>
      </>
    );
  }
}
