import React, { useEffect, useState } from "react";
import HeaderBanner from "../../components/core/header-banner/HeaderBanner";
import axios from "axios";
import { useQuery } from "react-query";
import Accordion from 'react-bootstrap/Accordion';
import { Helmet } from "react-helmet";
import { SITETITLE } from "../../constants";
import { toast } from "react-toastify";

const getBannerData = async () => {
  const response = await axios.get(window.apiBaseUrl+'help-faqs/banner');  
  return response.data.data;
};

// const getFaqsListData = async () => {
//   const response = await axios.get(window.apiBaseUrl+'help-faqs/faqs-list');  
//   return response.data.data;
// };

const getMetaData = async () => {
  const response = await axios.get(window.apiBaseUrl+'help-faqs/meta-details');  
  return response.data.data;
};

const HelpFaqs = () => {
  const { data: banner, error, isLoading } = useQuery("bannerData", getBannerData);    
  const { data: meta } = useQuery("metaData", getMetaData);    
  // const { data: faqsList } = useQuery("faqsList", getFaqsListData);    
  const metaData = meta;
  const headerBannerData = banner;    
  const [faqsList, setData] = useState([]);
  const [ToggleState, setToggleState] = useState(1);

  const tabList = [
    'student','faculty','enterprise','government'
  ]

  let changeUrl = "/help-faqs/faqs-list?faqUser=student";

  const toggleTab = (index,value) => {
    setToggleState(index);
    changeUrl = value?"/help-faqs/faqs-list?faqUser="+value:'';
    getData(changeUrl);
  };

  const getActiveClass = (index, className) =>
    ToggleState === index ? className : "";

  useEffect(()=> {    
    getData(changeUrl);      
  },[changeUrl]);

  const getData = async (value) => {
    await axios.get(window.apiBaseUrl+value)
    .then((res) => {
      if(res.status === 200){
        setData(res.data.data);
      } else {
        toast.warn(res.data.message, {          
          autoClose: 5000,          
          theme: "colored",
        });
      }
    })
    .catch((err) => {      
      toast.error(err.response.data.message, {        
        autoClose: 5000,        
        theme: "colored",
        });
    }); 
  };

  if (isLoading) return <div>Fetching posts...</div>;
  if (error) return <div>An error occurred: {error.message}</div>;

  return (
    <>
      <Helmet>          
          <title>{metaData?(metaData.metaTitle):SITETITLE}</title>          
          <meta name='description' content={metaData?(metaData.metaDescription):SITETITLE} />
          <meta name='keywords' content={metaData?(metaData.metaKeywords):SITETITLE} />
      </Helmet>
      {headerBannerData?(
      <HeaderBanner
        imgUrl={headerBannerData.bannerImage}
        subtitle={headerBannerData.bannerHeading}
        title={headerBannerData.bannerSubHeading}
        description={headerBannerData.bannerDescription}
        buttonsOne={headerBannerData.buttonNameOne}
        buttonsLinkOne={headerBannerData.buttonLinkOne}
        buttonsTwo={headerBannerData.buttonNameTwo}
        buttonsLinkTwo={headerBannerData.buttonLinkTwo}
      />
      ):''}

      <section className="faq-tabs">
        <div className="container">
          <ul className="nav nav-tabs">
            {tabList.map((key,index) =>(
            <li key={index+1} className="nav-item">
              <a
                className={`nav-link ${getActiveClass(index+1, "active")}`}
                onClick={() => toggleTab(index+1,key)}
                id={`${key}-tab`}
                data-toggle="pill"                
                href={`#${key}`}
                data-value={key}                
                role="tab"
                aria-controls={`${key}`}
                aria-selected="true"
              >
                {key}
              </a>
            </li>
            ))}
          </ul>

          <div className="tab-content">
            {tabList.map((key,index) =>(              
            <div key={index+1}
              className={`tab-pane container ${getActiveClass(index+1, "active")}`}
              id={`${key}`}
              role="tabpanel"
              aria-labelledby={`${key}-tab`}
            >
              <section className="terms-qutaion pt-5 pb-5">
                <div className="container">
                <Accordion defaultActiveKey="0">
                  {faqsList.map((faqs,index) => (
                    <Accordion.Item key={index+1} eventKey={index+1}>
                      <Accordion.Header>{faqs.question}</Accordion.Header>
                      <Accordion.Body><div dangerouslySetInnerHTML={{ __html: faqs.answer }}></div></Accordion.Body>
                    </Accordion.Item>                    
                    ))}
                  </Accordion>                  
                </div>
              </section>
            </div>   
            ))}
          </div>
        </div>
      </section>
    </>
  );
}

export default HelpFaqs;
