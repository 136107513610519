import React from "react";
import logo from "../../assets/website/image/logo.png";
import googlePlayLogo from "../../assets/website/image/google-play.png";
import godaddySslLogo from "../../assets/website/image/godaddy-ssl.png";
import iso2015Logo from "../../assets/website/image/iso2015.webp";
import appStoreLogo from "../../assets/website/image/app-store.png";
import studentAppLogo from "../../assets/website/image/student-app.svg";
import instructorAppLogo from "../../assets/website/image/instructor-app.svg";
import { Link } from "react-router-dom";

const FooterComponent = () => {
  return (
    <>
      {/* Main Footer Start */}
      <footer className="new-footer">
        <div className="container">
          <div className="row">
            <div className="col-md-4">
              <div className="new-footer-logo">
                <div className="new-footer-logo-one">
                  <img src={logo} alt="logo" />
                  <p>
                    Matriye Academy is India’s largest online learning platform,
                    with over 3,000+ courses taught by expert instructors. Get
                    started today by downloading our apps!
                  </p>
                  <a href="https://play.google.com/store/apps/details?id=com.maitriyeacademy.student">
                    <img src={googlePlayLogo} alt="google-play" />
                  </a>
                  <a href="https://apps.apple.com/in/app/matriye-academy/id6444727490">
                    <img src={appStoreLogo} alt="app-store" />
                  </a>
                </div>
                <div className="new-footer-logo-two new-footer-logo-two-mobile">
                  <h6>Reach out to us!</h6>
                  <p>
                    Have questions about studying at Matriye Academy? Give us a
                    call and we'll be happy to answer them for you!
                  </p>
                  <a href="/">
                    <p>
                      <i className="fa fa-phone"></i> 08045849180
                    </p>
                  </a>
                </div>
              </div>
            </div>
            <div className="col-md-2 col-6 mt-3">
              <div className="new-footer-link">
                <h6>Pages</h6>
                <ul className="list">
                  <li>
                    <Link to="/about-us">About Us</Link>
                  </li>
                  <li>
                    <Link to="/for-enterprise">For Enterprise</Link>
                  </li>
                  <li>
                    <Link to="/for-government">For Government</Link>
                  </li>
                  <li>
                    <Link to="/blogs">Blogs</Link>
                  </li>
                </ul>
              </div>
            </div>
            <div className="col-md-2 col-6 mt-3">
              <div className="new-footer-link">
                <h6>Help</h6>
                <ul className="list">
                  <li>
                    <Link to="/help-faqs">Help/FAQS</Link>
                  </li>
                  <li>
                    <Link to="/notice-board">Notice Board</Link>
                  </li>
                  <li>
                    <Link to="/career">Careers</Link>
                  </li>
                  <li>
                    <Link to="/contact-us">Contact Us</Link>
                  </li>
                </ul>
              </div>
            </div>
            <div className="col-md-2 col-6 mt-3">
              <div className="new-footer-link">
                <h6>Information</h6>
                <ul className="list">
                  <li>
                    <Link to="/terms-and-conditions">
                      Terms &amp; Conditions
                    </Link>
                  </li>
                  <li>
                    <Link to="/privacy-policy">Privacy Policy</Link>
                  </li>
                  <li>
                    <Link to="/refund-policy">Refund Policy</Link>
                  </li>
                  <li>
                    <Link to="/cookie-policy">Cookie</Link>
                  </li>
                </ul>
              </div>
            </div>
            <div className="col-md-2 col-6 mt-3">
              <div className="new-footer-link">
                <h6>Our Apps</h6>
                <ul className="list">
                  <li>
                    <a href="https://play.google.com/store/apps/details?id=com.maitriyeacademy.student">
                      <img src={studentAppLogo} alt="student-app" />
                      Student App
                    </a>
                  </li>
                  <li>
                    <a href="https://play.google.com/store/apps/details?id=com.maitriyeacademy.instructor">
                      <img src={instructorAppLogo} alt="instructor-app" />
                      Faculty App
                    </a>
                  </li>
                </ul>
              </div>
            </div>
          </div>
          <div className="new-footer-iews-outer">
            <div className="row">
              <div className="col-md-4">
                <div className="new-footer-logo-two new-footer-logo-two-desktop">
                  <h6>Reach out to us!</h6>
                  <p>
                    Have questions about studying at Matriye Academy? Give us a
                    call and we'll be happy to answer them for you!
                  </p>
                  <a href="/">
                    <p>
                      <i className="fa fa-phone"></i> 08045849180
                    </p>
                  </a>
                </div>
              </div>
              <div className="col-md-4 offset-md-4">
                <div className="new-footer-news">
                  <div className="subscribe-form">
                    <h6>Subscribe Newsletter</h6>
                    <form
                      action="/"
                      className="form-horizontal"
                      method="post"
                      id="userNewsletter"
                      name="userNewsletter"
                      autoComplete="off"
                      acceptCharset="utf-8"
                    >
                      <div className="form-group">
                        <input
                          type="email"
                          name="email"
                          placeholder="Your email"
                          required=""
                        />
                        <button
                          type="submit"
                          className="submit-btn submit"
                          aria-label="newsletter"
                        >
                          <span className="icon flaticon-right-arrow-2">
                            
                          </span>
                        </button>
                      </div>
                    </form>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <hr />
          <div className="new-footer-bottom ">
            <div className="row align-items-center">
              <div className="col-md-4">
                <div className="new-footer-copyright">
                  <a href="/">
                    <p>Matriye Academy 2021, All Rights Reserved.</p>
                  </a>
                </div>
              </div>
              <div className="col-md-5 text-center">
                <div className="certificate">
                  <img
                    src={godaddySslLogo}
                    className="network domain security, domain security check"
                    alt="godaddy-logi"
                  />
                  <img
                    src={iso2015Logo}
                    className="iso certified 9001, iso certified logo"
                    alt="iso-logo"
                  />
                  <a
                    href="https://www.dmca.com/Protection/Status.aspx?ID=6574f096-c526-4a35-94e7-6494f8af1909&amp;refurl=https://www.matriyeacademy.com/"
                    title="DMCA.com Protection Status"
                    className="dmca-badge"
                  >
                    {" "}
                    <img
                      src="https://images.dmca.com/Badges/_dmca_premi_badge_4.png?ID=6574f096-c526-4a35-94e7-6494f8af1909"
                      alt="DMCA.com Protection Status"
                    />
                  </a>
                  <script src="https://images.dmca.com/Badges/DMCABadgeHelper.min.js">
                    {" "}
                  </script>
                </div>
              </div>
              <div className="col-md-3 text-xl-right text-center">
                <div className="new-footer-social">
                  <ul className="social-box">
                    <li className="twitter">
                      <a
                        href="https://twitter.com/MatriyeA"
                        className="fa fa-twitter"
                      >
                        {" "}
                      </a>
                    </li>
                    <li className="linkedin">
                      <a
                        href="https://www.linkedin.com/company/matriye-academy/"
                        className="fa fa-linkedin"
                      >
                        {" "}
                      </a>
                    </li>
                    <li className="facebook">
                      <a
                        href="https://www.facebook.com/MatriyeAcademyEdTech?mibextid=LQQJ4d"
                        className="fa fa-facebook-f"
                      >
                        {" "}
                      </a>
                    </li>
                    <li className="instagram">
                      <a
                        href="https://www.instagram.com/matriyeacademy/"
                        className="fa fa-instagram"
                      >
                        {" "}
                      </a>
                    </li>
                    <li className="quora">
                      <a
                        href="https://www.quora.com/profile/Matriye-Academy/"
                        className="fa fa-quora"
                      >
                        {" "}
                      </a>
                    </li>
                    <li className="pinterest">
                      <a
                        href="https://www.youtube.com/@matriyeacademy6181"
                        className="fa fa-youtube"
                      >
                        {" "}
                      </a>
                    </li>
                  </ul>
                </div>
              </div>
            </div>
          </div>
        </div>
      </footer>
      {/* Main Footer End */}
      <button className="back-btn" id="backBtn">
        <i className="fa fa-angle-up"></i>
      </button>
    </>
  );
};

export default FooterComponent;
