import axios from "axios";
import React, { useEffect, useState } from "react";
import { useQuery } from "react-query";
import { Link } from "react-router-dom";
import { toast } from "react-toastify";

const getCategoryData = async () => {
  const response = await axios.get(window.apiBaseUrl+'master/category');  
  return response.data.data;
};

function TopRatedCoursesComponent() {
  const { data: category, error, isLoading } = useQuery("categoryData", getCategoryData);    
  const categoryData = category;
  const [topRatedList, setData] = useState([]);
  const [ToggleState, setToggleState] = useState(1);

  let changeUrl = "/home/top-rated-courses?categoryId=3";


  const toggleTab = (index,value) => {
    setToggleState(index);
    changeUrl = value?"/home/top-rated-courses?categoryId="+value:'';
    getData(changeUrl);
  };

  const getActiveClass = (index, className) =>
    ToggleState === index ? className : "";
   
  useEffect(()=> {    
    getData(changeUrl);      
  },[changeUrl]);
  
  const getData = async (value) => {
    await axios.get(window.apiBaseUrl+value)
    .then((res) => {
      if(res.status === 200){
        setData(res.data.data);
      } else {
        toast.warn(res.data.message, {          
          autoClose: 5000,          
          theme: "colored",
        });
      }
    })
    .catch((err) => {      
      toast.error(err.response.data.message, {        
        autoClose: 5000,        
        theme: "colored",
        });
    }); 
  };

  if (isLoading) return <div>Fetching posts...</div>;
  if (error) return <div>An error occurred: {error.message}</div>;
  
  return (
    <>
      <div className="section section-padding">
        <div className="container">
          <div className="section-title text-center">
            <h2 className="title">
              <span>Top Rated</span> Learning Tutorials
            </h2>
          </div>
          {Array.isArray(categoryData)?(
          <div className="courses-wrapper">
            <div className="courses-tab-new">
              <ul className="nav nav-tabs" id="myTab" role="tablist">
                {categoryData.map((category,index) => (
                <li key={index+1} className="nav-item">
                  <a
                    className={`nav-link ${getActiveClass(index+1, "active")}`}
                    onClick={() => toggleTab(index+1,category.categoryId)}
                    id={`${category.categoryName}-tab`}
                    data-toggle="pill"
                    href={`#${category.categoryName.toLowerCase()}`}
                    role="tab"
                    aria-controls={`${category.categoryName}`}
                    aria-selected="true"
                  >
                    {category.categoryName}
                  </a>
                </li>
                ))}
              </ul>
              <div className="tab-content" id="pills-tabContent">
                {categoryData.map((category,index) => (
                  <div key={index+1}
                    className={`tab-pane fade ${getActiveClass(index+1,"show active")}`}
                    id={`${category.categoryName.toLowerCase()}`}
                    role="tabpanel"
                    aria-labelledby={`${category.categoryName}-tab`}
                  >
                    <div className="row">
                    {topRatedList.map((courses,index) => (
                      <div key={index+1} className="col-xl-3 col-lg-4 col-md-4 d-flex">
                        <div className="single-course flex-fill" id="1">
                          <div className="courses-image">
                            <div className="course-tag">
                              <p>{courses.categoryName}</p>
                            </div>
                            <Link
                              to={`/courses/${courses.courseNameSlugUrl}/${courses.subjectNameSlugUrl}`}
                              className="courseLink_1"
                            >
                              <img
                                src={courses.subjectPicture}
                                alt={courses.subjectName}
                                loading="lazy"
                                />
                            </Link>
                          </div>
                          <div className="courses-content">
                            <div className="top-meta">
                              <Link
                                className="tag courseName_1"
                                to={`/courses/${courses.courseNameSlugUrl}/${courses.subjectNameSlugUrl}`}
                              >
                                {courses.subjectName}
                              </Link>
                              <span className="price">
                                <span className="sale-price subjectPrice_1">
                                  Rs. {courses.subjectPrice}
                                </span>
                              </span>
                            </div>
                            <p className="author-name">{courses.departmentName}</p>
                            <span className="course-name-tag">{courses.courseName}</span>
                          </div>
                          <div className="courses-meta">
                            <div className="course-new-list-outer">
                              {courses.courseContentLessonsCount?(
                              <div className="course-new-list-inner">
                                  <i className="fa fa-book"></i>
                                  <p>{courses.courseContentLessonsCount}</p>
                              </div>
                              ):''}
                              <div className="course-new-list-inner">
                                <i className="fa fa-users"></i>
                                <p>100 Seats</p>
                              </div>
                              {courses.courseDuration?(
                              <div className="course-new-list-inner">
                                  <i className="fa fa-calendar"></i>
                                  <p>{courses.courseDuration}&nbsp;{courses.courseDurationIn}</p>
                              </div>
                              ):''}
                            </div>
                            <div className="add-btn">
                              <Link to={`/courses/${courses.courseNameSlugUrl}/${courses.subjectNameSlugUrl}`} className="add-cart-btn">
                                View Course
                              </Link>
                            </div>
                          </div>
                        </div>
                      </div>
                    ))}
                    </div>
                  </div>   
                ))}
              </div>
            </div>
            <div className="courses-more text-center">
              <p>
                <strong>3,000+</strong> more skillful courses you can explore
              </p>
              <Link
                to="/filter"
                className="btn btn-primary btn-hover-heading-color"
              >
                Browse more courses
              </Link>
            </div>
          </div>
          ):''}
        </div>
      </div>
    </>
  );
}

export default TopRatedCoursesComponent;
