import React from "react";
import bannerLogo2 from "../../../assets/website/image/banner2.webp";
import { Link } from "react-router-dom";

const BannerComponent = (props) => {  
  return (
    <>
      {props.data && (
        <div className="slider-wrapper slider-area banner-style-1 bg-image height-940 d-flex align-items-center">
          <div className="container">
            <div className="row align-items-center">
              <div className="order-1 order-xl-2 col-lg-6 col-xl-6">
                <div className="content">
                  <span className="pre-title sal-animate">
                    {props.data.bannertitle}
                  </span>
                  <h1 className="title sal-animate">
                    {props.data.bannerHighlightHeading} <span>{props.data.bannerHeading}</span>
                  </h1>
                  <p className="description sal-animate"></p>
                  <div dangerouslySetInnerHTML={{ __html: props.data.bannerDescription }}></div>
                  
                  {props.data.bannerListText?(
                  <ul className="banner-icon-list-items">
                    {props.data.bannerListText.map((item, index) => (
                    <li key={index} className="banner-icon-list-item">                      
                      <i className="fa fa-check" aria-hidden="true"></i>
                      <span className="elementor-icon-list-text">
                        {item}
                      </span>
                    </li>
                    ))}
                  </ul>
                  ):''}

                  <div className="read-more-btn sal-animate">
                    <a
                      className="btn btn-primary btn-hover-heading-color"
                      href={props.data.bannerButtonLink}
                    >
                      {props.data.bannerButtonName}
                      <i className="icon-arrow-right-line-right"></i>
                    </a>
                  </div>
                </div>
              </div>
              <div className="order-2 order-xl-2 col-lg-6 col-xl-6 banner-right-content">
                <div className="row g-5">
                  <div className="col-lg-6 col-md-6 col-sm-6 edu-card-wrapper">
                    <div className="edu-card card-type-6 radius-small sal-animate">
                      <div className="inner">
                        <div className="thumbnail">
                          <a href="/">
                            <img
                              loading="lazy"
                              className="w-100 lazyloaded"
                              alt="Course Meta"
                              src={props.data.boxOneImage}
                            />
                          </a>
                        </div>
                        <div className="content">
                          <ul className="edu-meta meta-04">
                            <li>
                              <i className="icon-file-list-3-line"></i>
                              {props.data.boxOneLessions}
                            </li>
                            <li>
                              <i className="icon-time-line"></i>{props.data.boxOneTiming}
                            </li>
                          </ul>
                          <h6 className="title">
                            <a href="/">
                              {props.data.boxOneDescription}
                            </a>
                          </h6>
                          <div className="card-bottom">
                            <Link
                              className="edu-btn btn-secondary btn-small"
                              to={props.data.boxOneButtonLink}
                            >
                              {props.data.boxOneButtonName}
                            </Link>
                          </div>
                        </div>
                      </div>
                    </div>
                    <div className="work-shop sal-animate hero-shap-5">
                      <div className="inner">
                        <img
                          loading="lazy"
                          src={bannerLogo2}
                          className="lazyloaded"
                          alt="rating"
                        />
                        <div className="thumbnail">
                          <div className="hero-card"></div>
                        </div>
                        <div className="content">
                          <h6 className="title">{props.data.boxTwoHeading}</h6>
                          <span className="time">{props.data.boxTwoDescription}</span>
                          <div>
                            <Link
                              to={props.data.boxTwoButtonLink}
                              className="edu-btn btn-secondary btn-small"
                              data-toggle="modal"
                              data-target="#login"
                            >
                              {props.data.boxTwoButtonName}
                            </Link>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="col-md-6 col-sm-6">
                    <div className="video-thumbnail eduvibe-hero-one-video">
                      <div className="thumbnail sal-animate">
                        <img
                          loading="lazy"
                          className="w-100 lazyloaded"
                          alt="Video Images"
                          src={props.data.boxThreeImage}
                        />
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      )}
    </>
  );
};

export default BannerComponent;
