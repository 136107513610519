import axios from "axios";
import React, { useEffect, useState }  from "react";
import { Accordion } from "react-bootstrap";
import { Helmet } from "react-helmet";
import { useQuery } from "react-query";
import { useParams } from "react-router";
import { Link } from "react-router-dom";
import { toast } from "react-toastify";
import { SITETITLE } from "../constants";
import { useCart } from "react-use-cart";

const getStartLearningData = async () => {
  const response = await axios.get(window.apiBaseUrl+'subject/start-learning-today');  
  return response.data.data;
};

const CourseDetails = () => {
  const [isSticky, setIsSticky] = useState(false);
  
  const { data: startLearning, error, isLoading } = useQuery("startLearningData", getStartLearningData);    
  const startLearningData = startLearning;    
  
  const { courseNameSlugUrl } = useParams();
  const { subjectNameSlugUrl } = useParams();

  const [subjectId, setSubjectId] = useState("");
  const [subjectDetails, setSubjectDetails] = useState([]);
  const [contentList, setContentList] = useState([]);
  const [relatedSubjectList, setRelatedSubjectList] = useState([]);
  const [subjectStructureList, setSubjectStructureList] = useState([]);
  const [subjectInstructorList, setSubjectInstructorList] = useState([]);
  const [subjectFaqsList, setSubjectFaqsList] = useState([]);
  const [subjectReviewRatingList, setSubjectReviewRatingList] = useState([]);

  useEffect(()=> {   
    
    const handleScroll = () => {
      const scrollPosition = window.scrollY;
      const targetElement = document.getElementById('scrollTriggerId');

      if (targetElement) {
        const targetPosition = targetElement.offsetTop;
        const isStickyNow = scrollPosition >= targetPosition;

        setIsSticky(isStickyNow);
      }
    };

    getSubjectDetailsData();
    if(subjectId){         
      getContentListData(subjectId);
      getRelatedSubjectListData(subjectId);
      getSubjectStructureData(subjectId);
      getSubjectInstructorData(subjectId);
      getSubjectFaqsListData(subjectId);
      getSubjectReviewRatingListData(subjectId);
    }

    window.addEventListener('scroll', handleScroll);

    // Clean up the event listener on component unmount
    return () => {
      window.removeEventListener('scroll', handleScroll);
    };

    
  },[subjectId]);

  const stickyClass = isSticky ? 'sticky-bar-comm sticky-bar-sticky' : 'sticky-bar-comm';

  const formData = new FormData();
  formData.append("courseNameSlugUrl", courseNameSlugUrl);
  formData.append("subjectNameSlugUrl", subjectNameSlugUrl);

  const getSubjectDetailsData = async () => {
    await axios.post(window.apiBaseUrl+'/subject/get-subject-details', formData, {
      headers: { "Content-Type": "multipart/form-data" }
    })
    .then((res) => {
      if(res.status === 200){
        setSubjectDetails(res.data.data);
        setSubjectId(res.data.data.decodeSubjectId);
      } else {
        toast.warn(res.data.message, {          
          autoClose: 5000,          
          theme: "colored",
        });
      }
    })
    .catch((err) => {      
      toast.error(err.response.data.message, {        
        autoClose: 5000,        
        theme: "colored",
        });
    }); 
  };

  const getContentListData = async (val) => {    
    await axios.post(window.apiBaseUrl+'/subject/content-list', {"subjectId": val}, {
      headers: { "Content-Type": "multipart/form-data" }
    })
    .then((res) => {
      if(res.status === 200){
        setContentList(res.data.data);
      } else {
        toast.warn(res.data.message, {          
          autoClose: 5000,          
          theme: "colored",
        });
      }
    })
    .catch((err) => {
      console.log(err.response.data.message);
    }); 
  };

  const getRelatedSubjectListData = async (val) => {    
    await axios.post(window.apiBaseUrl+'/subject/related-subject-list', {"subjectId": val}, {
      headers: { "Content-Type": "multipart/form-data" }
    })
    .then((res) => {
      if(res.status === 200){
        setRelatedSubjectList(res.data.data);
      } else {
        toast.warn(res.data.message, {          
          autoClose: 5000,          
          theme: "colored",
        });
      }
    })
    .catch((err) => {
      console.log(err.response.data.message);
    }); 
  };
  
  const getSubjectStructureData = async (val) => {    
    await axios.post(window.apiBaseUrl+'/subject/subject-structure', {"subjectId": val}, {
      headers: { "Content-Type": "multipart/form-data" }
    })
    .then((res) => {
      if(res.status === 200){
        setSubjectStructureList(res.data.data);
      } else {
        toast.warn(res.data.message, {          
          autoClose: 5000,          
          theme: "colored",
        });
      }
    })
    .catch((err) => {
      console.log(err.response.data.message);
    }); 
  };
  
  const getSubjectInstructorData = async (val) => {    
    await axios.post(window.apiBaseUrl+'/subject/subject-instructor', {"subjectId": val}, {
      headers: { "Content-Type": "multipart/form-data" }
    })
    .then((res) => {
      if(res.status === 200){
        setSubjectInstructorList(res.data.data);
      } else {
        toast.warn(res.data.message, {          
          autoClose: 5000,          
          theme: "colored",
        });
      }
    })
    .catch((err) => {
      console.log(err.response.data.message);
    }); 
  };
  
  const getSubjectFaqsListData = async (val) => {    
    await axios.post(window.apiBaseUrl+'/subject/subject-faqs', {"subjectId": val}, {
      headers: { "Content-Type": "multipart/form-data" }
    })
    .then((res) => {
      if(res.status === 200){
        setSubjectFaqsList(res.data.data);
      } else {
        toast.warn(res.data.message, {          
          autoClose: 5000,          
          theme: "colored",
        });
      }
    })
    .catch((err) => {
      console.log(err.response.data.message);
    }); 
  };

  const getSubjectReviewRatingListData = async (val) => {    
    await axios.post(window.apiBaseUrl+'/subject/rating-review-list', {"subjectId": val}, {
      headers: { "Content-Type": "multipart/form-data" }
    })
    .then((res) => {
      if(res.status === 200){
        setSubjectReviewRatingList(res.data.data);
      } else {
        toast.warn(res.data.message, {          
          autoClose: 5000,          
          theme: "colored",
        });
      }
    })
    .catch((err) => {
      console.log(err.response.data.message);
    }); 
  };

  const { addItem, inCart  } = useCart();

  const handleAddToCart = () => {
    
    const product = {
      id: subjectDetails.decodeSubjectId,
      subjectName: subjectDetails.subjectName,
      courseName: subjectDetails.courseName,
      image: subjectDetails.subjectPicture,
      price: subjectDetails.subjectPrice,      
    };        
    addItem(product);
  };
  
  if (isLoading) return <div>Fetching posts...</div>;
  if (error) return <div>An error occurred: {error.message}</div>;
  
  return (
    <>    
      <Helmet>          
          <title>{subjectDetails.metaTitle?subjectDetails.metaTitle:SITETITLE}</title>          
          <meta name='description' content={subjectDetails.metaDescription?subjectDetails.metaDescription:SITETITLE} />
          <meta name='keywords' content={subjectDetails.metaKeywords?subjectDetails.metaKeywords:SITETITLE} />
      </Helmet>
      {subjectDetails.subjectPicture&&subjectDetails.subjectName?    
      <div className="section page-banner-section bg-color-1 course_detail_banner"
        style={{
          backgroundImage: `url(${subjectDetails.subjectPicture})`,
        }}
      >
        <div className="container">
          <div className="course-details-banner-content">
            <h1 className="title">{subjectDetails.subjectName} </h1>
            <div className="detail_category_part">
              <p className="detail_category">{subjectDetails.categoryName} |</p>
              <p className="detail_category">{subjectDetails.courseName} </p>
            </div>            
            <p>{subjectDetails.subjectLongDescription}</p>
            <div className="course-details-meta">
              {subjectDetails.creatorName?
              <div className="meta-action">
                <div className="meta-author">
                  <img
                    src={subjectDetails.creatorImage}
                    alt={subjectDetails.creatorName}
                    loading="lazy"
                  />
                </div>
                <div className="meta-name">
                  <h5 className="name">{subjectDetails.creatorName}</h5>
                </div>
                <div className="meta-action">
                  <p className="label">
                    Last Update :<span>{subjectDetails.lastUpdatedDate}</span>
                  </p>
                </div>
                <div className="rating">
                  <div className="rating-star">
                    <div className="rating-active" style={{width: `${Math.round(subjectDetails.subjectRating*2)}0%`}}></div>
                  </div>
                  <span>({subjectDetails.subjectRating})</span>
                </div>
              </div>
              :''}
            </div>
          </div>
        </div>
      </div>
      :''}

      <div className="main_newblock about-new-top products-details-blocks">
        <div className={stickyClass}>
          <div className="container">
            <ul>
              <li>
                <a href="#about-info">About</a>
              </li>
              <li>
                <a href="#how-it-works">How It Works</a>
              </li>
              <li>
                <a href="#courses">Courses</a>
              </li>
              <li>
                <a href="#instructors">Instructors</a>
              </li>
              <li>
                <a href="#enrollment-options">Enrollment Options</a>
              </li>
              <li>
                <a href="#fa-questions">FAQ</a>
              </li>
            </ul>
          </div>
        </div>
      </div>

      <div className="section section-padding pt-5" id="scrollTriggerId">
        <div className="container">
          <div className="row justify-content-between">

            {subjectDetails.courseOverviewHeading||subjectDetails.courseContentHeading?
            <div className="col-xl-7 col-lg-8 order-xl-1 order-lg-1 order-md-2 order-sm-2">
              <div className="course-details-wrapper">                
                <div className="course-overview" id="about-info">
                  <h2 className="title">{subjectDetails.courseOverviewHeading}</h2>                                    
                  <div dangerouslySetInnerHTML={{ __html: subjectDetails.courseOverviewDescription }}></div>                
                </div>
                {subjectDetails.whatYouWillLearnListText.length>1?
                <div className="course-learn-list">
                  <h3 className="title">{subjectDetails.whatYouWillLearnHeading}</h3>
                  {subjectDetails.whatYouWillLearnListText?(
                  <ul>
                  {subjectDetails.whatYouWillLearnListText.map((list, index) => (
                    <li key={index}>{list}</li>
                  ))}
                  </ul>                  
                  ):''}
                </div>
                :''}

                {subjectDetails.courseContentHeading?
                <div className="course-lessons">
                  <div className="lessons-top">
                    <h3 className="title">{subjectDetails.courseContentHeading}</h3>
                    <div className="lessons-time">
                      <span>{subjectDetails.courseContentLessonsCount}</span>
                      <span>{subjectDetails.courseContentLessionsTime}</span>
                    </div>
                  </div>
                  {contentList?(
                  <Accordion defaultActiveKey="0">
                    {contentList.map((list,index)=>(
                    <Accordion.Item eventKey={index+1} key={index+1}>
                      <Accordion.Header>{list.courseContentListTitle}</Accordion.Header>
                      <Accordion.Body><div dangerouslySetInnerHTML={{ __html: list.courseContentListDescription }}></div></Accordion.Body>
                    </Accordion.Item>
                    ))}
                  </Accordion>
                  ):''}
                  <div className="info-text-wrapper mt-4">
                    <h3 className="title">{subjectDetails.courseContentSectionOneHeading}</h3>
                    <div dangerouslySetInnerHTML={{ __html: subjectDetails.courseOverviewDescription }}></div>
                  </div>
                  <div className="info-text-wrapper mt-4">
                    <h3 className="title">{subjectDetails.courseContentSectionTwoHeading}</h3>
                    <div dangerouslySetInnerHTML={{ __html: subjectDetails.courseContentSectionTwoDescription }}></div>
                  </div>
                </div>
                :''}
              </div>
            </div>
            :''}

            <div className="col-lg-4 order-xl-2  order-lg-2 order-md-1 order-sm-1 mt-3">
              <div className="sidebar-details-wrap">
                {subjectDetails.subjectPrice?
                <div className="sidebar-details-video-description">
                  <div className="sidebar-video">
                    <img
                      src={subjectDetails.thumbnailImage}
                      alt="video-thumbnail"
                    />
                    <a href={subjectDetails.videoLink} className="play videoLightbox">
                      <i className="fa fa-play"></i>
                    </a>
                  </div>

                  <div className="sidebar-description">

                    <div className="price">
                      <span className="sale-price">Rs. {subjectDetails.subjectPrice} </span>
                      <span className="regular-price"></span>
                    </div>
                    <div className="courses-btn">
                      {inCart(subjectDetails.decodeSubjectId)?(
                        <>
                        <Link to={"/cart"} className="btn btn-warning enterprise-twobtn">
                          <i className="fa fa-shopping-cart"></i>&nbsp;
                          Go to cart
                        </Link>
                        </>
                      ):(
                      <>
                      <a href="/" className="btn btn-primary btn-hover-heading-color">
                        Buy it now
                      </a>
                      <button onClick={handleAddToCart} className="btn btn-outline-primary enterprise-twobtn">
                        <i className="fa fa-shopping-cart"></i>&nbsp;
                        Add To Cart
                      </button>
                      </>
                      )}
                    </div>
                    <ul className="description-list">
                      {subjectDetails.courseDuration?
                      <li>
                        <i className="fa fa-clock-o"></i> Duration
                        <span>{subjectDetails.courseDuration}&nbsp;{subjectDetails.courseDurationIn}</span>
                      </li>
                      :''}
                      {subjectDetails.courseLevel?
                      <li>
                        <i className="fa fa-sliders"></i> Level
                        <span>{subjectDetails.courseLevel}</span>
                      </li>
                      :''}
                      {subjectDetails.courseLectures?
                      <li>
                        <i className="fa fa-file-o"></i> Lectures
                        <span>{subjectDetails.courseLectures}</span>
                      </li>
                      :''}
                      {subjectDetails.courseLanguage?
                      <li>
                        <i className="fa fa-language"></i> Language
                        <span>{subjectDetails.courseLanguage}</span>
                      </li>
                      :''}                      
                      <li>
                        <i className="fa fa-user-o"></i> Enrolled
                        <span>100+ Enrolled</span>
                      </li>                      
                      {subjectDetails.courseVideos?
                      <li><i className="fa fa-video-camera"></i> Recorded Video
                        <span>{subjectDetails.courseVideos}</span>
                      </li>
                      :''}
                      {subjectDetails.courseTheorys?
                      <li><i className="fa fa-file-text-o"></i> Notes
                          <span>{subjectDetails.courseTheorys}</span>
                      </li>
                      :''}
                      {subjectDetails.courseMCQs?
                      <li><i className="fa fa-list"></i> MCQs
                          <span>{subjectDetails.courseMCQs}</span>
                      </li>
                      :''}
                      {subjectDetails.coursePPTs?
                      <li><i className="fa fa-file"></i> PPTs
                          <span>{subjectDetails.coursePPTs}</span>
                      </li>
                      :''}
                      {subjectDetails.courseLiveSeries?
                      <li><i className="fa fa-desktop"></i> Live Test Series
                          <span>{subjectDetails.courseLiveSeries}</span>
                      </li>
                      :''}
                      {subjectDetails.courseEbooks?
                      <li><i className="fa fa-book"></i> E-Book
                          <span>{subjectDetails.courseEbooks}</span>
                      </li>
                      :''}                      
                    </ul>

                    <a className="w-100 mb-2" href="/">
                      <i className="fa fa-share"></i> Share This Course
                    </a>
                    <ul className="social-box">
                      <li className="twitter">
                        <a
                          href="http://twitter.com/intent/tweet?url=http://localhost/matriyeacademy/courses/cbse-12th/history"
                          className="fa fa-twitter"
                        >
                          {" "}
                        </a>
                      </li>
                      <li className="linkedin">
                        <a
                          href="https://www.linkedin.com/shareArticle?url=http://localhost/matriyeacademy/courses/cbse-12th/history"
                          className="fa fa-linkedin"
                        >
                          {" "}
                        </a>
                      </li>
                      <li className="facebook">
                        <a
                          href="https://www.facebook.com/sharer/sharer.php?u=http://localhost/matriyeacademy/courses/cbse-12th/history"
                          className="fa fa-facebook-f"
                        >
                          {" "}
                        </a>
                      </li>
                      <li className="pinterest">
                        <a
                          href="http://pinterest.com/pin/create/button/?url=http://localhost/matriyeacademy/courses/cbse-12th/history"
                          className="fa fa-pinterest-p"
                        >
                          {" "}
                        </a>
                      </li>
                    </ul>

                  </div>
                </div>
                :''}

                {relatedSubjectList?(
                <div className="sidebar-details-course">
                  <h4 className="sidebar-details-title">Releted Courses</h4>
                  <ul className="sidebar-details-courses">
                    {relatedSubjectList.map((relatedList, index)=>(
                    <li key={index}>
                      <div className="sidebar-course-item">
                        <div className="item-image">
                          <Link to={`/courses/${relatedList.courseNameSlugUrl}/${relatedList.subjectNameSlugUrl}`}>                          
                            <img
                              src={relatedList.subjectPicture}
                              alt={relatedList.subjectName}
                            />
                          </Link>
                        </div>
                        <div className="item-content">
                          <h3 className="title">
                          <Link to={`/courses/${relatedList.courseNameSlugUrl}/${relatedList.subjectNameSlugUrl}`}>                          
                            {relatedList.subjectName}
                            </Link>
                          </h3>
                          <span className="price">
                            <strong>Rs. {relatedList.subjectPrice}</strong>
                          </span>
                        </div>
                      </div>
                    </li>
                    ))}
                  </ul>
                </div>
                ):''}
              </div>
            </div>

            {subjectDetails.professionalCertificateHeading&&subjectDetails.professionalCertificateDescription&&subjectDetails.professionalCertificateImage?
            <div className="col-md-12 mt-5 order-3" id="how-it-works">
              <div className="course-overview">
                <h3 className="title">{subjectDetails.professionalCertificateHeading}</h3>
                <div className="row mt-3">
                  <div className="col-md-7">
                    <div dangerouslySetInnerHTML={{ __html: subjectDetails.professionalCertificateDescription }}></div>
                  </div>
                  <div className="col-md-5">
                    <img
                      src={subjectDetails.professionalCertificateImage}
                      alt={subjectDetails.professionalCertificateHeading}
                      loading="lazy"
                    />
                  </div>
                </div>
              </div>
            </div>
            :''}

            {subjectStructureList?(
            <div className="col-md-12 mt-5 professional-certificate order-3" id="courses">
              <h3 className="title mb-4">Course Structure </h3>
              {subjectStructureList.map((list, index)=>(
              <div className="course-instructor" key={index+1}>
                <div className="instructor-profile">
                  <div className="profile-images text-center">
                    <span>
                      <h2>{index+1}</h2>
                    </span>
                  </div>
                  <div className="profile-content">
                    <h5 className="name">{index+1}. {list.syllabusHeading}</h5>
                    <div className="profile-meta">
                      <div className="rating">
                        <div className="rating-star">
                          <div className="rating-active" style={{width: `${Math.round(list.syllabusStarRating*2)}0%`}}></div>
                        </div>
                        <span>{list.syllabusStarRating}</span>
                      </div>
                      <span className="meta-action">
                        <i className="fa fa-play-circle"></i>&nbsp;{list.syllabusRating} ratings
                      </span>
                      <span className="meta-action">
                        <i className="fa fa-user-o"></i>&nbsp;{list.syllabusNoOfReviews} reviews
                      </span>
                    </div>
                    <div dangerouslySetInnerHTML={{ __html: list.syllabusDescription }}></div>
                  </div>
                </div>
              </div>
              ))}
            </div>
            ):''}

            {subjectInstructorList?(subjectInstructorList.length>1?(
            <div className="col-md-12 mt-5 order-4" id="instructors">
              <h3 className="title">Course Instructor</h3>
              <div className="row">
                {subjectInstructorList.map((instructors, index)=>(
                <div className="col-md-6" key={index}>
                  <div className="course-instructor mb-5">
                    <div className="instructor-profile">
                      <div className="profile-images">
                        <img src={instructors.profilePicture}
                          alt="instructor"
                          loading="lazy" />
                      </div>
                      <div className="profile-content">
                        <h5 className="name">{instructors.fullName}</h5>
                        <div className="profile-meta">
                          <div className="rating">
                            <div className="rating-star">
                              <div className="rating-active" style={{width: `${Math.round(instructors.starRating*2)}0%`}}></div>
                            </div>
                            <span>({instructors.starRating})</span>
                          </div>
                          <span className="meta-action">
                            <i className="fa fa-play-circle"></i>
                            {instructors.noOfTutorial} Tutorials
                          </span>
                          <span className="meta-action">
                            <i className="fa fa-user-o"></i>
                            {instructors.noOfStudents} Students
                          </span>
                        </div>
                        <div dangerouslySetInnerHTML={{ __html: instructors.courseInstructorDescription }}></div>
                      </div>
                    </div>
                  </div>
                </div>
                ))}
              </div>
            </div>
            ):''):''}

            {startLearningData.points?(
            <div className="col-md-12 mt-0 course-details-wrapper order-5" id="enrollment-options">
              <div className="row course-learn-list mt-0">
                <div className="col-md-7">
                  <h3>{startLearningData.heading}</h3>
                  <h6>{startLearningData.subHeading}</h6>
                  <ul>
                    {startLearningData.points.map((points,index) => (
                    <li key={index}>{points}</li>
                    ))}
                  </ul>
                </div>

                <div className="col-md-5">
                  <div className="share-certficate">
                    <h5>Shareable on</h5>
                    <ul className="social-box">
                      <li className="twitter">
                        <a href="/" className="fa fa-twitter">{""}</a>
                      </li>
                      <li className="linkedin">
                        <a href="https://www.linkedin.com/" className="fa fa-linkedin">{""}</a>
                      </li>
                      <li className="facebook">
                        <a href="http://facebook.com/" className="fa fa-facebook-f">{""}</a>
                      </li>
                      <li className="instagram">
                        <a href="https://www.instagram.com/" className="fa fa-instagram">{""}</a>
                      </li>
                      <li className="quora">
                        <a href="https://www.quora.com/" className="fa fa-quora">{""}</a>
                      </li>
                      <li className="pinterest">
                        <a href="http://pinterest.com/" className="fa fa-pinterest-p">{""}</a>
                      </li>
                    </ul>
                  </div>
                  <img src={startLearningData.image}
                    alt="certificate"
                  />
                  <h6 className="mt-3">
                    {startLearningData.imageDescription}
                  </h6>
                </div>
              </div>
            </div>
            ):''}

            <div className="col-md-12 mt-5 order-5">
              {subjectFaqsList?(
              <section className="terms-qutaion frequently-asked-questions" id="fa-questions">
                <h3 className="mb-3">Frequently Asked Questions</h3>
                <div className="panel-group accordion" id="another" role="tablist" aria-multiselectable="true">
                  <div className="panel panel-default">                  
                  <Accordion defaultActiveKey="0">
                    {subjectFaqsList.map((list,index)=>(
                    <Accordion.Item eventKey={index+1} key={index+1}>
                      <Accordion.Header>{list.question}</Accordion.Header>
                      <Accordion.Body><div dangerouslySetInnerHTML={{ __html: list.answer }}></div></Accordion.Body>
                    </Accordion.Item>
                    ))}
                  </Accordion>                  
                  </div>              
                </div>
              </section>
              ):''}

              {subjectReviewRatingList.averageRating?                    
              <div className="col-md-9 mt-5 order-8 mx-auto">
                <div className="course-review">
                    <h3 className="title">Review</h3>                        
                    <div className="review-rating">
                        <div className="rating-box">
                            <span className="count">{subjectReviewRatingList.averageRating}</span>
                            <div className="rating-star">
                                <div className="rating-active" style={{width: `${Math.round(subjectReviewRatingList.averageRating*2)}0%`}}></div>
                            </div>
                            <p>Total {subjectReviewRatingList.totalRatingRecord} Ratings</p>
                        </div>
                        <div className="rating-percentage">                                
                            <div className="single-rating-percentage">
                                <span className="label">5 Star</span>
                                <div className="rating-line">
                                    <div className="line-bar">
                                    </div>
                                </div>
                            </div>                                
                            <div className="single-rating-percentage">
                                <span className="label">4 Star</span>
                                <div className="rating-line">
                                    <div className="line-bar">
                                    </div>
                                </div>
                            </div>                                
                            <div className="single-rating-percentage">
                                <span className="label">3 Star</span>
                                  <div className="rating-line">
                                    <div className="line-bar">
                                    </div>
                                </div>
                            </div>                                
                            <div className="single-rating-percentage">
                                <span className="label">2 Star</span>
                                  <div className="rating-line">
                                    <div className="line-bar">
                                    </div>
                                </div>
                            </div>                                
                            <div className="single-rating-percentage">
                                <span className="label">1 Star</span>
                                  <div className="rating-line">
                                    <div className="line-bar">
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>     
                    {subjectReviewRatingList.reviewList?(
                    <div className="review-items">
                        <ul>
                          {subjectReviewRatingList.reviewList.map((review,index)=>(
                          <li key={index}>
                              <div className="single-review">
                                  <div className="review-author">
                                      <img src={review.profilePicture} alt={review.reviewRatingId} loading="lazy" />
                                  </div>
                                  <div className="review-content">
                                      <div className="review-top">
                                          <h4 className="name">{review.fullName}</h4>
                                          <div className="rating-star">
                                              <div className="rating-active" style={{width: `${Math.round(review.rating*2)}0%`}}>{review.rating}</div>
                                          </div>
                                          <span className="date">{review.reviewDate}</span>
                                      </div>
                                      <h6>{review.userType}</h6>
                                      <p>{review.message}</p>
                                  </div>
                              </div>                                        
                          </li>
                          ))}
                        </ul>
                    </div>   
                    ):''}                     
                </div>
              </div>
              :''}

                <div className="col-md-8 mt-5 comment-form order-6 mx-auto">
                  <hr />
                  <div className="group-title mb-3">
                    <h5>Leave A Comment</h5>
                  </div>
                  <form
                    action="http://localhost/matriyeacademy/user-review/store"
                    className="form-horizontal"                    
                    method="post"
                    id="frmReviewRatingStore"
                    name="frmReviewRatingStore"
                    acceptCharset="utf-8"
                    noValidate="novalidate"
                  >
                    <input
                      type="hidden"
                      name="subjectId"
                      defaultValue="VmtaYVJrOVdRbEpRVkRBOQ=="
                    />
                    <div className="row clearfix">
                      <div className="col-lg-6 col-md-6 col-sm-12 form-group">
                        <div className="star-rating">
                          <input
                            type="radio"
                            id="5-stars"
                            name="rating"
                            defaultValue="5"
                          />
                          <label htmlFor="5-stars" className="star">
                            ★
                          </label>
                          <input
                            type="radio"
                            id="4-stars"
                            name="rating"
                            defaultValue="4"
                          />
                          <label htmlFor="4-stars" className="star">
                            ★
                          </label>
                          <input
                            type="radio"
                            id="3-stars"
                            name="rating"
                            defaultValue="3"
                          />
                          <label htmlFor="3-stars" className="star">
                            ★
                          </label>
                          <input
                            type="radio"
                            id="2-stars"
                            name="rating"
                            defaultValue="2"
                          />
                          <label htmlFor="2-stars" className="star">
                            ★
                          </label>
                          <input
                            type="radio"
                            id="1-star"
                            name="rating"
                            defaultValue="1"
                          />
                          <label htmlFor="1-star" className="star">
                            ★
                          </label>
                        </div>
                      </div>
                      <div className="col-lg-12 col-md-12 col-sm-12 form-group">
                        <textarea
                          className="darma"
                          name="message"
                          placeholder="Your Message"
                        ></textarea>
                      </div>
                      <div className="col-lg-12 col-md-12 col-sm-12 form-group">
                        <button
                          className="btn btn-outline-primary"
                          type="button"
                          data-toggle="modal"
                          data-target="#login"
                        >
                          Write A Review
                        </button>
                      </div>
                    </div>                
                </form>
              </div>              
              
            </div>
          </div>
        </div>
      </div>
    </>
  );

};

export default CourseDetails;
