import React from "react";

const Map = () => {
  return (
    <>
      <section className="new-contact">
        <div className="contact-map">
          <iframe
            src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3206.063025726616!2d73.91030467432077!3d18.561956967910476!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x3bc2c17a592378a7%3A0x5728b21915f3161e!2sMatriye%20Academy%20EdTech!5e1!3m2!1sen!2sin!4v1700225280130!5m2!1sen!2sin"
            width="100%"
            height="400"
            style={{ border: 0 }}
            loading="lazy"
            referrerPolicy="no-referrer-when-downgrade"
          ></iframe>
        </div>
      </section>
    </>
  );
};

export default Map;
