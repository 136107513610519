import React from "react";
import HeaderBanner from "../../components/core/header-banner/HeaderBanner";
import InstructorCountUpComponent from "../../components/page-wise-components/instructor/InstructorCountUpComponent";
import ComputingScreenComponent from "../../components/page-wise-components/home/ComputingScreenComponent";
import AppSectionComponent from "../../components/page-wise-components/home/AppSectionComponent";
import axios from "axios";
import { useQuery } from "react-query";
import PackageComponent from "../../components/page-wise-components/instructor/PackageComponent";
import VideoComponent from "../../components/page-wise-components/instructor/VideoComponent";
import FeaturedPlanComponent from "../../components/page-wise-components/instructor/FeaturedPlanComponent";
import WhyChooseUsComponent from "../../components/page-wise-components/instructor/WhyChooseUsComponent";
import ForTeachingComponent from "../../components/page-wise-components/instructor/ForTeachingComponent";
import { Helmet } from "react-helmet";
import { SITETITLE } from "../../constants";

const getBanner = async () => {
  const response = await axios.get(window.apiBaseUrl+'for-instructor/banner');  
  return response.data.data;
};

const getCounterData = async () => {
  const response = await axios.get(window.apiBaseUrl+'for-instructor/counter');  
  return response.data.data;
};

const getPackageData = async () => {
  const response = await axios.get(window.apiBaseUrl+'for-instructor/digital-content-plan');  
  return response.data.data;
};

const getAnimatedVideoData = async () => {
  const response = await axios.get(window.apiBaseUrl+'for-instructor/animated-demo-videos');  
  return response.data.data;
};

const getFeaturedPlanData = async () => {
  const response = await axios.get(window.apiBaseUrl+'for-instructor/features');  
  return response.data.data;
};

const getWhyChooseUsData = async () => {
  const response = await axios.get(window.apiBaseUrl+'for-instructor/best-lms-software');  
  return response.data.data;
};

const getComputerScreenData = async () => {
  const response = await axios.get(window.apiBaseUrl+'for-instructor/complete-mobile-web-solutions');  
  return response.data.data;
};

const getForTeachingData = async () => {
  const response = await axios.get(window.apiBaseUrl+'for-instructor/best-edtech-teaching-software');  
  return response.data.data;
};

const getDownloadAppData = async () => {
  const response = await axios.get(window.apiBaseUrl+'for-instructor/download-apps');  
  return response.data.data;
};

const getMetaData = async () => {
  const response = await axios.get(window.apiBaseUrl+'for-instructor/meta-details');  
  return response.data.data;
};

const Instructor = () => {
  const { data: banner, error, isLoading } = useQuery("bannerData", getBanner);    
  const { data: meta } = useQuery("metaData", getMetaData);    
  const { data: counter } = useQuery("counterData", getCounterData);    
  const { data: packages } = useQuery("packageData", getPackageData);    
  const { data: animatedVideo } = useQuery("animatedVideoData", getAnimatedVideoData);    
  const { data: featuredPlan } = useQuery("featuredPlanData", getFeaturedPlanData);    
  const { data: whyChooseUs } = useQuery("whyChooseUsData", getWhyChooseUsData);    
  const { data: computerScreen } = useQuery("computerScreen", getComputerScreenData);    
  const { data: forTeaching } = useQuery("forTeaching", getForTeachingData);    
  const { data: downloadApp } = useQuery("downloadApp", getDownloadAppData);

  const metaData = meta;
  const headerBannerData = banner;
  const counterData = counter;  
  const packagesData = packages;  
  const animatedVideoData = animatedVideo;  
  const featuredPlanData = featuredPlan;  
  const whyChooseUsData = whyChooseUs;  
  const computerScreenData = computerScreen;  
  const forTeachingData = forTeaching;  
  const downloadAppData = downloadApp;  

  if (isLoading) return <div>Fetching posts...</div>;
  if (error) return <div>An error occurred: {error.message}</div>;

  return (
    <>
      <Helmet>          
          <title>{metaData?(metaData.metaTitle):SITETITLE}</title>          
          <meta name='description' content={metaData?(metaData.metaDescription):SITETITLE} />
          <meta name='keywords' content={metaData?(metaData.metaKeywords):SITETITLE} />
      </Helmet>
      {headerBannerData?(
      <HeaderBanner
        imgUrl={headerBannerData.bannerImage}
        subtitle={headerBannerData.bannerHeading}
        title={headerBannerData.bannerSubHeading}
        description={headerBannerData.bannerDescription}
        buttonsOne={headerBannerData.buttonNameOne}
        buttonsLinkOne={headerBannerData.buttonLinkOne}
        buttonsTwo={headerBannerData.buttonNameTwo}
        buttonsLinkTwo={headerBannerData.buttonLinkTwo}
      />
      ):''}
      {counterData?(
      <InstructorCountUpComponent data={counterData}/>
      ):''}
      {packagesData?(
      <PackageComponent data={packagesData}/>
      ):''}
      {animatedVideoData?(
      <VideoComponent data={animatedVideoData}/> 
      ):''}   
      {featuredPlanData?(
      <FeaturedPlanComponent data={featuredPlanData}/>
      ):''}
      {whyChooseUsData?(
      <WhyChooseUsComponent data={whyChooseUsData} />
      ):''}
      {computerScreenData?(
      <ComputingScreenComponent data={computerScreenData.mobileWebSolutionHeading} dataList={computerScreen.imageList}/>
      ):''}
      {forTeachingData?(
      <ForTeachingComponent data={forTeachingData}/>
      ):''}
      {downloadAppData?(
      <AppSectionComponent data={downloadAppData}/>      
      ):''}
    </>
  );
};

export default Instructor;
