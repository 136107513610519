import React from "react";

const AppSectionComponent = (props) => {
  return (
    <>
      <section className="app-section app-section-inst">
        <div className="container">
          <div className="row align-items-center">
            <div className="col-md-6">
              <div className="app-section-content">
                <h5>{props.data.mainHeading}</h5>
                <div className="app-section-list">
                {props.data.appsPoints?(
                  <ul>
                    {props.data.appsPoints.map((item, index) => (
                      <li key={index}>{item}</li>
                    ))}
                  </ul>
                ):''}
                </div>
                <a href="https://play.google.com/store/apps/details?id=com.maitriyeacademy.student">
                  <img
                    src={props.data.playStoreImage}
                    alt="google-play"
                  />
                </a>{" "}
                <a href="https://apps.apple.com/in/app/matriye-academy/id6444727490">
                  <img
                    src={props.data.appStoreImage}
                    alt="app-store"
                  />
                </a>
              </div>
            </div>
            <div className="col-md-6 text-center">
              <div className="app-section-image">
                <img
                  className="lazyloaded"
                  alt="download-section"
                  src={props.data.sectionImage}
                />
              </div>
            </div>
          </div>
        </div>
      </section>
    </>
  );
};

export default AppSectionComponent;
