import React from "react";
import KeyFeatures from "../../components/core/key-features/KeyFeatures";
import HeaderBanner from "../../components/core/header-banner/HeaderBanner";
import InfoBlock from "../../components/core/info-block/InfoBlock";
import axios from "axios";
import { useQuery } from "react-query";
import { Helmet } from "react-helmet";
import { SITETITLE } from "../../constants";
import { useFormik } from 'formik';
import { forGovernment } from "../../config/formValidation";
import { toast } from "react-toastify";


const getBanner = async () => {
  const response = await axios.get(window.apiBaseUrl+'for-government/banner');  
  return response.data.data;
};

const getCountryData = async () => {
  const response = await axios.get(window.apiBaseUrl+'master/country');  
  return response.data.data;
};

const getInfoSectionData = async () => {
  const response = await axios.get(window.apiBaseUrl+'for-government/career-academy');  
  return response.data.data;
};

const getDemandSkillsData = async () => {
  const response = await axios.get(window.apiBaseUrl+'for-government/demand-skills');  
  return response.data.data;
};

const getWhyChooseUsData = async () => {
  const response = await axios.get(window.apiBaseUrl+'for-government/get-your-workforce-ready');  
  return response.data.data;
};

const getMetaData = async () => {
  const response = await axios.get(window.apiBaseUrl+'for-government/meta-details');  
  return response.data.data;
};

const initialValues = {
  firstName:"",
  lastName:"",
  jobTitle:"",
  email:"",
  phone:"",
  concernPersonTitle:"",
  interestedIn:"",
  numberLearner:"",
  countryName:"",
  message:"",
}

const ForGovernment = () => {
  const { data: banner, error, isLoading } = useQuery("bannerData", getBanner);    
  const { data: meta } = useQuery("metaData", getMetaData);    
  const { data: infoSectionData} = useQuery("infoSection", getInfoSectionData);
  const { data: whyChooseUsData} = useQuery("getWhyChooseUsData", getWhyChooseUsData);    
  const { data: demandSkillsData} = useQuery("demandSkills", getDemandSkillsData);    
  const { data: countryData} = useQuery("getCountry", getCountryData);    
  const metaData = meta;  
  const headerBannerData = banner;
  const infoBlock = infoSectionData;
  const whyChooseUs = whyChooseUsData;  
  const demandSkills = demandSkillsData;  
  const country = countryData;  

  const {values, errors, dirty, isValid, isSubmitting, touched, handleBlur, handleChange, handleSubmit} = useFormik({
    initialValues: initialValues,
    validationSchema: forGovernment,
    onSubmit: async (values,action) => {
      await axios.post(window.apiBaseUrl+'/for-government/sales-request', values, {
        headers: { "Content-Type": "multipart/form-data" }
      })
      .then((res) => {
        if(res.status === 200){                
          toast.success(res.data.message, {          
            autoClose: 5000,          
            theme: "colored",
          }); 
          action.resetForm();       
        } else {
          toast.warn(res.data.message, {          
            autoClose: 5000,          
            theme: "colored",
          });
        }
      })
      .catch((err) => {      
        toast.error(err.response.data.message, {        
          autoClose: 5000,        
          theme: "colored",
          });
      }); 
    }
  });

  if (isLoading) return <div>Fetching posts...</div>;
  if (error) return <div>An error occurred: {error.message}</div>;

  return (
    <>      
      <Helmet>          
          <title>{metaData?(metaData.metaTitle):SITETITLE}</title>          
          <meta name='description' content={metaData?(metaData.metaDescription):SITETITLE} />
          <meta name='keywords' content={metaData?(metaData.metaKeywords):SITETITLE} />
      </Helmet>
      {headerBannerData?(
      <HeaderBanner
        imgUrl={headerBannerData.bannerImage}
        subtitle={headerBannerData.bannerHeading}
        title={headerBannerData.bannerSubHeading}
        description={headerBannerData.bannerDescription}
        buttonsOne={headerBannerData.buttonNameOne}
        buttonsLinkOne={headerBannerData.buttonLinkOne}
        buttonsTwo={headerBannerData.buttonNameTwo}
        buttonsLinkTwo={headerBannerData.buttonLinkTwo}
      />
      ):''}
      {whyChooseUs?(
      <KeyFeatures
        pageTitle={whyChooseUs.staticData?(whyChooseUs.staticData.preWorkforceHeading):''}
        pageSubTitle={whyChooseUs.staticData?(whyChooseUs.staticData.preWorkforceSubHeading):''}
        content={whyChooseUs.workforceList}
        customClass="col-md-3"
      />
      ):''}
      {demandSkills?(
      <>
      <section
        className="page-title-section section-department section-department2"
        id="our-mission"
        style={{
          backgroundImage: 'url("https://matriyeacademy.com/website-assets/images/For_government_demand_skill_bg.png")'
        }}
      >
        <div className="auto-container">
          <div className="content-box">
            <h2 className="title">
              <small>{demandSkills.staticData.demandSkillHeading}</small>
              <br />
              {demandSkills.staticData.demandSkillSubHeading}
            </h2>
            <div className="text">            
              <div dangerouslySetInnerHTML={{ __html: demandSkills.staticData.demandSkillDescription }}></div>
            </div>
          </div>
        </div>
      </section>
      </>
      ):''}
      {demandSkills?(
      <>
      <section className="faq-blocks-section add-ons pb-5 government-page">
        <div className="auto-container">
          <div className="inner-container">
          {demandSkills.demandSkillList?(
            <div className="row clearfix">
            {demandSkills.demandSkillList.map((feature, index) => (
              <div key={index} className="faq-block col-lg-6 col-md-6 col-sm-12">
                <div className="inner-box">
                  <div className="faq-image">
                    <img
                      src={feature.demandSkillImage}
                      alt="government"
                    />
                  </div>
                  <div className="government-space">
                    <h5>
                      <a href="/">{feature.demandSkillHeading}</a>
                    </h5>
                    <div className="text">
                      <div dangerouslySetInnerHTML={{ __html: feature.demandSkillDescription }}></div>
                    </div>
                    {feature.demandSkillSlugUrl?
                    <>
                    <a
                      className="btn btn-primary btn-hover-heading-color"
                      href={feature.demandSkillSlugUrl}
                    >
                      Learn more
                    </a>
                    </>
                    :''}                    
                  </div>
                </div>
              </div>              
              ))} 
            </div>
          ):''}
          </div>
        </div>
      </section>   
      </>
      ):''}   
      {infoBlock?(
      <InfoBlock
        imgUrl={infoBlock.careerAcademyImage}
        subtitle={infoBlock.careerAcademySubHeading}
        title={infoBlock.careerAcademyHeading}
        description={infoBlock.careerAcademyDescription}
        buttonsOne={infoBlock.careerAcademyButtonName}
        buttonsLinkOne={infoBlock.careerAcademyButtonLink}
      />
      ):''}
      <section className="workforce" id="workforce">
        <div className="container">
          <h2 className="title text-center">
            Are you ready to transform your workforce?
          </h2>
          <form
          onSubmit={handleSubmit}          
            className="form-horizontal"
            autoComplete="off"
            id="contact-form"            
          >
            <div className="row">
              <div className="form-group col-md-6">
                <input
                  type="text"
                  name="firstName"
                  id="firstName"
                  className="isAlpha"
                  maxLength="15"
                  placeholder="First Name"
                  value={values.firstName}
                  onChange={handleChange}
                  onBlur={handleBlur}                  
                />
                {errors.firstName && touched.firstName ? <p className="text-danger">{errors.firstName}</p> : null}
              </div>
              <div className="form-group col-md-6">
                <input
                  type="text"
                  name="lastName"
                  id="lastName"
                  className="isAlpha"
                  maxLength="15"
                  placeholder="Last Name"
                  value={values.lastName}
                  onChange={handleChange}
                  onBlur={handleBlur}
                />
                {errors.lastName && touched.lastName ? <p className="text-danger">{errors.lastName}</p> : null}
              </div>
              <div className="form-group col-md-6">
                <input
                  type="text"
                  name="jobTitle"
                  id="jobTitle"
                  placeholder="Job Title"
                  value={values.jobTitle}
                  onChange={handleChange}
                  onBlur={handleBlur}
                />
                {errors.jobTitle && touched.jobTitle ? <p className="text-danger">{errors.jobTitle}</p> : null}
              </div>
              <div className="form-group col-md-6">
                <input
                  type="text"
                  name="concernPersonTitle"
                  id="concernPersonTitle"
                  placeholder="Organization Name"
                  value={values.concernPersonTitle}
                  onChange={handleChange}
                  onBlur={handleBlur}
                />
                {errors.concernPersonTitle && touched.concernPersonTitle ? <p className="text-danger">{errors.concernPersonTitle}</p> : null}
              </div>
              <div className="form-group col-md-6">
                <input
                  type="text"
                  className="isInteger"
                  name="phone"
                  id="phone"
                  placeholder="Phone"
                  maxLength="10"
                  value={values.phone}
                  onChange={handleChange}
                  onBlur={handleBlur}
                />
                {errors.phone && touched.phone ? <p className="text-danger">{errors.phone}</p> : null}
              </div>
              <div className="form-group col-md-6">
                <input
                  type="email"
                  name="email"
                  id="email"
                  maxLength="30"
                  placeholder="Email"
                  value={values.email}
                  onChange={handleChange}
                  onBlur={handleBlur}
                />
                {errors.email && touched.email ? <p className="text-danger">{errors.email}</p> : null}
              </div>
              <div className="form-group col-md-6">
                <select
                  className="form-control"
                  name="interestedIn"
                  id="interestedIn"
                  value={values.interestedIn}
                  onChange={handleChange}
                  onBlur={handleBlur}
                >
                  <option defaultValue="">
                    Who are you interested in training?
                  </option>
                  <option defaultValue="Citizens">Citizens</option>
                  <option defaultValue="Public officials">
                    Public officials
                  </option>
                  <option defaultValue="Both">Both</option>
                </select>
                {errors.interestedIn && touched.interestedIn ? <p className="text-danger">{errors.interestedIn}</p> : null}
              </div>
              <div className="form-group col-md-6">
                <select
                  className="form-control"
                  name="numberLearner"
                  id="numberLearner"
                  value={values.numberLearner}
                onChange={handleChange}
                onBlur={handleBlur}
                >
                  <option defaultValue="">Expected Number of Learners</option>
                  <option defaultValue="1-20">1-20</option>
                  <option defaultValue="21-100">21-100</option>
                  <option defaultValue="101-500">101-500</option>
                  <option defaultValue="501-1000">501-1000</option>
                </select>
                {errors.numberLearner && touched.numberLearner ? <p className="text-danger">{errors.numberLearner}</p> : null}
              </div>
              <div className="form-group col-md-6">
              {Array.isArray(country)?(
              <select
                className="form-control"
                name="countryName"
                id="countryName"
                value={values.countryName}
                onChange={handleChange}
                onBlur={handleBlur}
              >
                <option defaultValue="">Select Country...</option>
                {country.map((country, index)=>(
                <option key={index} defaultValue={country.countryName}>{country.countryName}</option>
                ))}
              </select>              
              ):''}
              {errors.countryName && touched.countryName ? <p className="text-danger">{errors.countryName}</p> : null}
              </div>
              <div className="form-group col-md-12">
                <textarea
                  className=""
                  name="message"
                  id="message"
                  placeholder="Please briefly describe your needs:"
                  value={values.message}
                  onChange={handleChange}
                  onBlur={handleBlur}
                ></textarea>
                {errors.message && touched.message ? <p className="text-danger">{errors.message}</p> : null}
              </div>
              <div className="form-group col-md-12">
                <p>
                  By filling out the form above, you agree to our Terms of
                  Service and Privacy Notice. We may reach you using this
                  information and/or utilize data from third parties to enhance
                  your experience.
                </p>
              </div>
              <div className="form-group mt-4">
                <button
                  className="btn btn-primary btn-hover-heading-color submit"
                  type="submit" disabled={!(dirty && isValid && !isSubmitting)}
                >
                  {isSubmitting? 'Please wait...': 'Send Message'}
                </button>
              </div>
            </div>
          </form>
        </div>
      </section>
    </>
  );
};

export default ForGovernment;
