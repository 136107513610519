import React from "react";

const AboutUsCeoInfo = () => {
  return (
    <>
      <section className="founder" id="our-ceo">
        <div className="container">
          <div className="row ">
            <div className="col-md-4">
              <div className="founder-image">
                <img
                  src="https://www.matriyeacademy.com/website-assets/images/founder.png"
                  alt=""
                />
              </div>
            </div>
            <div className="col-md-8">
              <div className="founder-content">
                <h4>About Co-Founder &amp; CEO</h4>
                <p>
                  In 2020, Kumar Sanjeev had the concept for Matriye Academy.
                  Matriye Academy edtech's vision was to provide personalized
                  education technology and a low-cost education to anybody and
                  wherever. Kumar Sanjeev is a marketer and technologist. He
                  graduated from Pune University with a master's degree in
                  business administration. He has over 8 years of consulting and
                  strategy design experience in e-commerce, the financial
                  market, online learning, and, more recently, the IT security
                  business. His ability to identify commercial opportunities and
                  aid organizations in achieving their goals sets him unique. He
                  currently oversees the organization's entire operations and
                  plays a key role in strategic decision-making.
                </p>
                <h6>Kumar Sanjeev</h6>
                <p className="designation-founder">CEO &amp; Co-Founder</p>
                <div className="founder-social">
                  <a href="mailto:admin@matriyeacademy.com">
                    <img
                      src="https://www.matriyeacademy.com/website-assets/images/gmail-logo.png"
                      alt=""
                    />
                  </a>
                  <a href="https://www.crunchbase.com/person/kumar-sanjeev-d5d1">
                    <img
                      src="https://www.matriyeacademy.com/website-assets/images/crunchbase-logo.png"
                      alt=""
                    />
                  </a>
                  <a href="https://www.linkedin.com/in/kumar-sanjeev-014809220/">
                    <img
                      src="https://www.matriyeacademy.com/website-assets/images/linkedin-logo.png"
                      alt=""
                    />
                  </a>
                  <a href="https://yourstory.com/companies/matriye-academy">
                    <img
                      src="https://www.matriyeacademy.com/website-assets/images/yourstory-logo.png"
                      alt=""
                    />
                  </a>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
    </>
  );
};

export default AboutUsCeoInfo;
