import React from "react";
import KeyFeatures from "../../components/core/key-features/KeyFeatures";
import HeaderBanner from "../../components/core/header-banner/HeaderBanner";
import InfoBlock from "../../components/core/info-block/InfoBlock";
import axios from "axios";
import { useQuery } from "react-query";
import { Helmet } from "react-helmet";
import { SITETITLE } from "../../constants";
import { useFormik } from "formik";
import { forEnterprise } from "../../config/formValidation";
import { toast } from "react-toastify";

const getBanner = async () => {
  const response = await axios.get(window.apiBaseUrl+'for-enterprise/banner');  
  return response.data.data;
};

const getCountryData = async () => {
  const response = await axios.get(window.apiBaseUrl+'master/country');  
  return response.data.data;
};

const getInfoSectionData = async () => {
  const response = await axios.get(window.apiBaseUrl+'for-enterprise/best-edtech-software');  
  return response.data.data;
};

const getWhyChooseUsData = async () => {
  const response = await axios.get(window.apiBaseUrl+'for-enterprise/why-choose-us');
  return response.data.data;
};

const getMetaData = async () => {
  const response = await axios.get(window.apiBaseUrl+'for-enterprise/meta-details');  
  return response.data.data;
};

const initialValues = {
  firstName: "",
  lastName: "",
  companyName: "",
  phone: "",
  workingEmailAddress: "",
  govtNonProfitAgency: "",
  companySize: "",
  expectedLearner: "",
  jobRole: "",
  jobFunction: "",
  countryName: "",
  message: "",
}

const ForEnterprise = () => {
  const { data: banner, error, isLoading } = useQuery("bannerData", getBanner);    
  const { data: meta } = useQuery("metaData", getMetaData);    
  const { data: infoSection} = useQuery("infoSection", getInfoSectionData);
  const { data: whyChooseUsData} = useQuery("getWhyChooseUsData", getWhyChooseUsData);    
  const { data: countryData} = useQuery("getCountry", getCountryData);    
    
  const metaData = meta;
  const headerBannerData = banner;
  const infoBlockData = infoSection;
  const whyChooseUs = whyChooseUsData;  
  const country = countryData;  

  const {values, errors, dirty, isValid, isSubmitting, touched, handleBlur, handleChange, handleSubmit} = useFormik({
    initialValues: initialValues,
    validationSchema: forEnterprise,
    onSubmit: async (values, action) => {         
      await axios.post(window.apiBaseUrl+'/for-enterprise/sales-request', values, {
        headers: { "Content-Type": "multipart/form-data" }
      })
      .then((res) => {
        if(res.status === 200){                
          toast.success(res.data.message, {          
            autoClose: 5000,          
            theme: "colored",
          }); 
          action.resetForm();       
        } else {
          toast.warn(res.data.message, {          
            autoClose: 5000,          
            theme: "colored",
          });
        }
      })
      .catch((err) => {      
        toast.error(err.response.data.message, {        
          autoClose: 5000,        
          theme: "colored",
          });
      });      
    },
  });  
  
  if (isLoading) return <div>Fetching posts...</div>;
  if (error) return <div>An error occurred: {error.message}</div>;

  return (
    <>
      <Helmet>          
          <title>{metaData?(metaData.metaTitle):SITETITLE}</title>          
          <meta name='description' content={metaData?(metaData.metaDescription):SITETITLE} />
          <meta name='keywords' content={metaData?(metaData.metaKeywords):SITETITLE} />
      </Helmet>    
      {headerBannerData?(
      <HeaderBanner
        imgUrl={headerBannerData.bannerImage}
        subtitle={headerBannerData.bannerHeading}
        title={headerBannerData.bannerSubHeading}
        description={headerBannerData.bannerDescription}
        buttonsOne={headerBannerData.buttonNameOne}
        buttonsLinkOne={headerBannerData.buttonLinkOne}
        buttonsTwo={headerBannerData.buttonNameTwo}
        buttonsLinkTwo={headerBannerData.buttonLinkTwo}
      />
      ):''}
      {infoBlockData?
      <InfoBlock
        imgUrl={infoBlockData.digitalCampusImage}
        subtitle={infoBlockData.digitalCampusSubHeading}
        title={infoBlockData.digitalCampusHeading}
        description={infoBlockData.digitalCampusDescription}
        buttonsOne=''
        buttonsLinkOne=''
      />
      :''}
      {whyChooseUs?(
      <KeyFeatures
        pageTitle={"Why Choose Us"}
        // pageSubTitle={"Why Join Us"}
        content={whyChooseUs}
        customClass="col-md-3"
      />
      ):''}

      <section className="workforce" id="workforce">
        <div className="container">
          <h2 className="title text-center">
            Are you ready to transform your workforce?
          </h2>
          <form onSubmit={handleSubmit} className="form-horizontal" id="contact-form" autoComplete="off">
          <div className="row" id="workforce">
            <div className="form-group col-md-6">
              <input
                type="text"
                name="firstName"
                id="firstName"
                className="isAlpha"
                maxLength="15"
                placeholder="First Name"
                value={values.firstName}
                onChange={handleChange}
                onBlur={handleBlur}
              />    
              {errors.firstName && touched.firstName ? <p className="text-danger">{errors.firstName}</p> : null}
            </div>            
            <div className="form-group col-md-6">
              <input
                type="text"
                name="lastName"
                id="lastName"
                className="isAlpha"
                maxLength="15"
                placeholder="Last Name"
                value={values.lastName}
                onChange={handleChange}
                onBlur={handleBlur}
              />
              {errors.lastName && touched.lastName ? <p className="text-danger">{errors.lastName}</p> : null}
            </div>
            <div className="form-group col-md-6">
              <input
                type="text"
                name="companyName"
                id="companyName"
                placeholder="Company Name"
                value={values.companyName}
                onChange={handleChange}
                onBlur={handleBlur}
              />
              {errors.companyName && touched.companyName ? <p className="text-danger">{errors.companyName}</p> : null}
            </div>
            <div className="form-group col-md-6">
              <input
                type="text"
                className="isInteger"
                name="phone"
                id="phone"
                placeholder="Phone"
                maxLength="10"
                value={values.phone}
                onChange={handleChange}
                onBlur={handleBlur}
              />
              {errors.phone && touched.phone ? <p className="text-danger">{errors.phone}</p> : null}
            </div>
            <div className="form-group col-md-6">
              <input
                type="email"
                name="workingEmailAddress"
                id="workingEmailAddress"
                maxLength="30"
                placeholder="Working Email Address"
                value={values.workingEmailAddress}
                onChange={handleChange}
                onBlur={handleBlur}
              />
              {errors.workingEmailAddress && touched.workingEmailAddress ? <p className="text-danger">{errors.workingEmailAddress}</p> : null}
            </div>
            <div className="form-group col-md-6">
              <select
                className="form-control"
                name="govtNonProfitAgency"
                id="govtNonProfitAgency"   
                value={values.govtNonProfitAgency}
                onChange={handleChange}
                onBlur={handleBlur}                             
              >
                <option defaultValue="">
                  Do you work for a government or nonprofit agency?
                </option>
                <option defaultValue="No">No</option>
                <option defaultValue="Yes">Yes</option>
              </select>
              {errors.govtNonProfitAgency && touched.govtNonProfitAgency ? <p className="text-danger">{errors.govtNonProfitAgency}</p> : null}
            </div>    
            <div className="form-group col-md-6">
              <select
                className="form-control"
                name="companySize"
                id="companySize"
                value={values.companySize}
                onChange={handleChange}
                onBlur={handleBlur}
              >
                <option defaultValue="">Company Size</option>
                <option defaultValue="1-500">1-500</option>
                <option defaultValue="501-1000">501-1000</option>
                <option defaultValue="1001-5000">1001-5000</option>
                <option defaultValue="5001-15000">5001-15000</option>
                <option defaultValue="15001+">15001+</option>
              </select>
              {errors.companySize && touched.companySize ? <p className="text-danger">{errors.companySize}</p> : null}
            </div>    
            <div className="form-group col-md-6">
              <select
                className="form-control"
                name="expectedLearner"
                id="expectedLearner"
                value={values.expectedLearner}
                onChange={handleChange}
                onBlur={handleBlur}
              >
                <option defaultValue="">Expected number of learners:</option>
                <option defaultValue="1-25">1-25</option>
                <option defaultValue="26-100">26-100</option>
                <option defaultValue="101-500">101-500</option>
                <option defaultValue="501-1000">501-1000</option>
                <option defaultValue="1001-5000">1001-5000</option>
                <option defaultValue="5001+">5001+</option>
              </select>
              {errors.expectedLearner && touched.expectedLearner ? <p className="text-danger">{errors.expectedLearner}</p> : null}
            </div>
            <div className="form-group col-md-6">
              <select className="form-control" name="jobRole" id="jobRole" value={values.jobRole}
              onChange={handleChange}
              onBlur={handleBlur}>
                <option defaultValue="">Select Job Role</option>
                <option defaultValue="C-Level">C-Level</option>
                <option defaultValue="VP">VP</option>
                <option defaultValue="Director">Director</option>
                <option defaultValue="Manager">Manager</option>
                <option defaultValue="Individual Contributor">
                  Individual Contributor
                </option>
                <option defaultValue="Other">Other</option>
              </select>
              {errors.jobRole && touched.jobRole ? <p className="text-danger">{errors.jobRole}</p> : null}
            </div>
            <div className="form-group col-md-6">
              <select
                className="form-control"
                name="jobFunction"
                id="jobFunction"  
                value={values.jobFunction}
                onChange={handleChange}
                onBlur={handleBlur}              
              >
                <option defaultValue="">Select Job Function</option>
                <option defaultValue="Analytics" >Analytics</option>
                <option defaultValue="Business Leader">
                  Business Leader
                </option>
                <option defaultValue="Data Science">Data Science</option>
                <option defaultValue="Engineering">Engineering</option>
                <option defaultValue="Finance">Finance</option>
                <option defaultValue="Human Resources">
                  Human Resources
                </option>
                <option defaultValue="Information Technology">
                  Information Technology
                </option>
                <option defaultValue="Learning &amp; Development">
                  Learning &amp; Development
                </option>
                <option defaultValue="Marketing">Marketing</option>
                <option defaultValue="Operations">Operations</option>
                <option defaultValue="Security">Security</option>
                <option defaultValue="Other">Other</option>
                <option defaultValue="Student">Student</option>
              </select>
              {errors.jobFunction && touched.jobFunction ? <p className="text-danger">{errors.jobFunction}</p> : null}
            </div>
            <div className="form-group col-md-6">
            {Array.isArray(country)?(
              <select
                className="form-control"
                name="countryName"
                id="countryName"
                value={values.countryName}
                onChange={handleChange}
                onBlur={handleBlur}
              >
                <option defaultValue="">Select Country...</option>
                {country.map((country, index)=>(
                <option key={index} defaultValue={country.countryName}>{country.countryName}</option>
                ))}
              </select>
              ):''}
              {errors.countryName && touched.countryName ? <p className="text-danger">{errors.countryName}</p> : null}
            </div>
            <div className="form-group col-md-12">
              <textarea
                className=""
                name="message"
                id="message"
                placeholder="Please briefly describe your needs:"
                value={values.message}
                onChange={handleChange}
                onBlur={handleBlur}
              ></textarea>
              {errors.message && touched.message ? <p className="text-danger">{errors.message}</p> : null}
            </div>
            <div className="form-group col-md-12">
              <p>
                By filling out the form above, you agree to our
                <a href="https://www.matriyeacademy.com/terms-and-conditions">
                  Terms of Service
                </a>{" "}
                and
                <a href="https://www.matriyeacademy.com/privacy-policy">
                  Privacy Notice
                </a>
                . We may reach you using this information and/or utilize data
                from third parties to enhance your experience.
              </p>
            </div>
            <div className="form-group mt-4">
              <button
                className="btn btn-primary btn-hover-heading-color submit"
                type="submit" disabled={!(dirty && isValid && !isSubmitting)}
              >
                {isSubmitting? 'Please wait...': 'Send Message'}
              </button>
            </div>
          </div>
          </form>          
        </div>
      </section>
    </>
  );
};

export default ForEnterprise;
