import React, { useEffect, useState } from "react";
import OwlCarousel from "react-owl-carousel";
import "owl.carousel/dist/assets/owl.carousel.css";
import "owl.carousel/dist/assets/owl.theme.default.css";

const TestimonialBlockComponent = (props) => {
  
  const [responsive, setResponsive] = useState({
    0: {
      items: 1,
    },
    450: {
      items: 2,
    },
    600: {
      items: 2,
    },
    1000: {
      items: 3,
    },
  });

  useEffect(() => {
    // Move data fetching code or side effects here if needed

    // Example: Update responsive based on data
    const updatedResponsive = {
      0: {
        items: 1,
      },
      450: {
        items: 2,
      },
      600: {
        items: 2,
      },
      1000: {
        items: 3,
      },
    };

    setResponsive(updatedResponsive);
  }, []);

  return (
    <>
      <div className="testimonial-wrapper">
        <div className="container">
          <div className="section-title text-center">
            <h2 className="title">{props.data.testimonialHeading} </h2>
            <h4>{props.data.testimonialSubHeading}</h4>
            <p>{props.data.testimonialDescription}</p>
          </div>
          <div className="testimonial-slider-content">
            <div className="owl-carousel owl-theme testimonial-slider owl-loaded owl-drag">
              <div className="owl-stage-outer">
                <div className="owl-stage">
                  <OwlCarousel
                    items={3}
                    className="owl-theme cloned"
                    loop
                    nav
                    margin={20}
                    autoplay={true}
                    dots={false}
                    responsive={responsive}
                  >
                    {props.dataList?(props.dataList.map((testimonial, index) => (
                    <div key={index} className="item">
                      <div className="testimonial-outer">
                        <div className="user-img">
                          <img
                            className="lazyload"
                            src={testimonial.testimonialImage}
                            alt={testimonial.name}
                          />
                        </div>                        
                        <div dangerouslySetInnerHTML={{ __html: testimonial.description }}></div>
                        <h5 className="name">{testimonial.name}</h5>
                        <span className="designation">
                          {testimonial.designation}
                        </span>
                      </div>
                    </div>
                    ))):''}
                    
                  </OwlCarousel>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );  
};

export default TestimonialBlockComponent;
