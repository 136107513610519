import axios from "axios";
import React from "react";
import { useQuery } from "react-query";
import { useFormik } from "formik";
import { forContactUs } from "../../../config/formValidation";
import { toast } from "react-toastify";

const getPageData = async () => {
  const response = await axios.get(window.apiBaseUrl+'contact-us/get-in-touch');  
  return response.data.data;
};

const initialValues = {
  name:"",
  email:"",
  phone:"",
  message:"",
}

const ContactForm = () => {
  const { data: page, error, isLoading } = useQuery("pageData", getPageData);    
  const pageData = page;  
  
  const {values, errors, dirty, isValid, isSubmitting, touched, handleBlur, handleChange, handleSubmit} = useFormik({
    initialValues: initialValues,
    validationSchema: forContactUs,
    onSubmit: async (values, action) => {
      await axios.post(window.apiBaseUrl+'/contact-us/leave-message', values, {
        headers: { "Content-Type": "multipart/form-data" }
      })
      .then((res) => {
        if(res.status === 200){                
          toast.success(res.data.message, {          
            autoClose: 5000,          
            theme: "colored",
          }); 
          action.resetForm();       
        } else {
          toast.warn(res.data.message, {          
            autoClose: 5000,          
            theme: "colored",
          });
        }
      })
      .catch((err) => {      
        toast.error(err.response.data.message, {        
          autoClose: 5000,        
          theme: "colored",
          });
      }); 
    }
  });
  
  if (isLoading) return <div>Fetching posts...</div>;
  if (error) return <div>An error occurred: {error.message}</div>;
  return (
    <>
      {pageData?    
      <>
      <section className="contact-page-section">
        <div className="pattern-layer-three"></div>
        <div className="container">
          <div className="row clearfix">
            <div className="info-column col-lg-6 col-md-12 col-sm-12">
              <div className="inner-column">
                <h2 className="title">{pageData.contactHeading}</h2>
                <h1>{pageData.contactSubHeading}</h1>
                <ul className="mt-5">
                  <li>
                    <span>Address</span>                    
                    <div dangerouslySetInnerHTML={{ __html: pageData.contactAddress }}></div>                
                  </li>
                  <li>
                    <span>Our Phone</span>
                    Telephone :<a href={`tel:${pageData.contactTelephone}`}>{pageData.contactTelephone} </a>
                    <br />
                    Mobile :<a href={`tel:${pageData.contactMobile}`}>{pageData.contactMobile} </a>
                  </li>
                  <li>
                    <span>Our Email</span>
                    <p>
                      <a href={`mailto::${pageData.contactFirstEmail}`}>
                      {pageData.contactFirstEmail}
                      </a>
                    </p>
                    <p>
                      <a href={`mailto::${pageData.contactSecondEmail}`}>
                      {pageData.contactSecondEmail}
                      </a>
                    </p>
                  </li>
                  <li>
                    <span>Opening Hours</span>{pageData.contactOpeningHours}
                  </li>
                </ul>
              </div>
            </div>

            <div className="form-column col-lg-6 col-md-12 col-sm-12">
              <div className="circle-layer"></div>
              <div className="inner-column">
                <h2>Leave a message</h2>
                <div className="text">
                  {pageData.contactLeaveMessageDescription}
                </div>

                <div className="contact-form">
                  <form
                    onSubmit={handleSubmit}
                    className="form-horizontal"
                    id="userContactUs"
                    autoComplete="off"                    
                  >
                    <div className="form-group">
                      <input
                        type="text"
                        name="name"
                        className="isAlpha"
                        maxLength="15"
                        id="name"
                        placeholder="Name"
                        value={values.name}
                        onChange={handleChange}
                        onBlur={handleBlur}
                      />
                      {errors.firstName && touched.firstName ? <p className="text-danger">{errors.firstName}</p> : null}
                    </div>
                    <div className="form-group">
                      <input
                        type="email"
                        name="email"
                        id="email"
                        maxLength="30"
                        placeholder="Email"
                        value={values.email}
                        onChange={handleChange}
                        onBlur={handleBlur}
                      />
                      {errors.email && touched.email ? <p className="text-danger">{errors.email}</p> : null}
                    </div>
                    <div className="form-group">
                      <input
                        type="text"
                        name="phone"
                        id="phone"
                        maxLength="10"
                        minLength="10"
                        className="isInteger"
                        placeholder="Phone"
                        value={values.phone}
                        onChange={handleChange}
                        onBlur={handleBlur}
                      />
                      {errors.phone && touched.phone ? <p className="text-danger">{errors.phone}</p> : null}
                    </div>
                    <div className="form-group">
                      <textarea
                        className=""
                        name="message"
                        id="message"
                        placeholder="Comment"
                        value={values.message}
                        onChange={handleChange}
                        onBlur={handleBlur}
                      ></textarea>
                      {errors.message && touched.message ? <p className="text-danger">{errors.message}</p> : null}
                    </div>
                    <div className="form-group mb-0">
                      <button
                        className="btn btn-outline-primary submit"
                        type="submit" disabled={!(dirty && isValid && !isSubmitting)}
                      >
                        {isSubmitting? 'Please wait...': 'Send Message'}
                      </button>
                    </div>
                  </form>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
      </>
      :''}
    </>
  );
};

export default ContactForm;
