import React, { useState } from "react";
import List from "../components/core/course-list/List";
import Grid from "../components/core/course-list/Grid";
import { Link } from "react-router-dom";
import { SITETITLE } from "../constants";
import { useQuery } from "react-query";   
import axios from "axios";
import { Helmet } from "react-helmet";
import Pagination from "./pagination";

// const getFilterData = async () => {
//   const response = await axios.get(
//     window.apiBaseUrl + "subject/subject-filter?offset=0&limit=8"
//   );
//   return response.data.data;
// };

const Filter = () => {
  let changeUrl = "";
  const [categoryName, setCategoryName] = useState("");
  const [categoryValue, setCategoryValue] = useState("");

  const [selectedLevel, setSelectedLevel] = useState("");
  

  const [currentPage, setCurrentPage] = useState(1);
  const [ITEMS_PER_PAGE] = useState(8);
  const [selectedDepartment, setSelectedDepartment] = useState("");
  const [selectedView, setSelectedView] = useState("list");

  const handleViewChange = (view) => {
    setSelectedView(view);
    setCurrentPage(1);
  };

  const handleChange = (event) => {
    const { name, value } = event.target;

    if (name === "category") {
      setCategoryName(name);
      setCategoryValue(value);
    } else if (name === "department") {
      console.log(categoryValue)
      setSelectedDepartment(value);
      console.log("deparment", )
    
    } else if (name === "level") {
      setSelectedLevel(value);
    }

    setCurrentPage(1);
  };

  const getFilterData = async (
    currentPage,
    itemsPerPage,
    categoryValue,
    departmentId,
  ) => {
    const offset = (currentPage - 1) * itemsPerPage;
    const response = await axios.get(
      window.apiBaseUrl +
        `subject/subject-filter?offset=${offset}&limit=${itemsPerPage}` +
        `&category=${categoryValue}&departmentId=${departmentId}`
    );
    return response.data.data;
  };

  const {
    data: filter,
    error,
    isLoading,
  } = useQuery(
    ["filterData", categoryValue, selectedDepartment, currentPage],
    () =>
      getFilterData(
        currentPage,
        ITEMS_PER_PAGE,
        categoryValue,
        selectedDepartment
      ),
    {
      staleTime: 30000, // 30 seconds
      refetchOnWindowFocus: false,
    }
  );

  const filterData = filter || {};
  const totalPages = Math.ceil(filterData.totalRecords / ITEMS_PER_PAGE);
  console.log(filterData);

  if (isLoading) return <div>Fetching posts...</div>;
  if (error) return <div>An error occurred: {error.message}</div>;

  return (
    <>
      <Helmet>
        <title>{SITETITLE} :: Courses list</title>
        <meta name="description" content="" />
        <meta name="keywords" content="" />
      </Helmet>
      <div
        className="section page-banner-section bg-color-1 course-list-banner"
        style={{
          backgroundImage: `url(
            "https://www.matriyeacademy.com/website-assets/images/bg-banner.png"
          )`,
        }}
      >
        <div className="container">
          <div className="page-banner-content">
            <h1 className="title">Courses to get you started</h1>
          </div>
        </div>
      </div>
      <div className="section section-padding">
        <div className="container">
          <div className="course-list-wrapper">
            <div className="row flex-row-reverse">
              <div className="col-lg-9">
                <div className="course-top-bar">
                  <div className="course-top-text">
                    <p>
                      We found{" "}
                      <span>{filterData ? filterData.totalRecords : "-"}</span>{" "}
                      Courses For You
                    </p>
                  </div>
                  <div className="course-top-inner">
                    <ul className="course-top-menu">
                      <span className="label">View</span>
                      <ul className="nav">
                        <li>
                          <Link
                            className={selectedView === "grid" ? "active" : ""}
                            onClick={() => handleViewChange("grid")}
                          >
                            <i className="fa fa-th-large"></i>
                          </Link>
                        </li>
                        <li>
                          {" "}
                          <Link
                            className={selectedView === "list" ? "active" : ""}
                            onClick={() => handleViewChange("list")}
                          >
                            <i className="fa fa-th-list"></i>
                          </Link>
                        </li>
                      </ul>
                    </ul>
                    <form className="filter" method="get">
                      <div className="course-top-action">
                        <span className="label">
                          <i
                            className="fa fa-align-left"
                            aria-hidden="true"
                          ></i>{" "}
                          Sort By:
                        </span>
                        <select className="select" name="sort" id="sort">
                          <option data-display="Default" defaultValue="">
                            --Select--
                          </option>
                          <option defaultValue="popularity">Popularity</option>
                          <option defaultValue="newestfirst">
                            Newest First
                          </option>
                        </select>
                      </div>
                    </form>
                  </div>
                </div>
                <div className="tab-content">
                  <div className="tab-pane fade show active" id="list">
                    {selectedView === "list" ? (
                      <List data={filterData.subjectList} />
                    ) : (
                      <Grid data={filterData.subjectList} />
                    )}
                  </div>
                </div>
                {/* <div className="page-pagination">
        <ul className="pagination justify-content-center">
          <li>
            <button
              disabled={currentPage === 1}
              onClick={() => setCurrentPage((prev) => prev - 1)}
            >
              <i className="fa fa-angle-left"></i>
            </button>
          </li>
          {Array.from({ length: totalPages }, (_, index) => (
            <li key={index}>
              <button
                onClick={() => setCurrentPage(index + 1)}
                className={currentPage === index + 1 ? "active" : ""}
              >
                {index + 1}
              </button>
            </li>
          ))}
          <li>
            <button
              disabled={currentPage === totalPages}
              onClick={() => setCurrentPage((prev) => prev + 1)}
            >
              <i className="fa fa-angle-right"></i>
            </button>
          </li>
        </ul>
      </div> */}
                <Pagination
                  totalPages={totalPages}
                  currentPage={currentPage}
                  onPageChange={setCurrentPage}
                />
              </div>
              <div className="col-lg-3">
                <div className="sidebar-wrap-02">
                  <form className="filter">
                    {Array.isArray(filterData.categoryList) ? (
                      <div className="sidebar-widget-02">
                        <h3 className="widget-title">Categories</h3>
                        <div className="widget-checkbox">
                          {Array.isArray(filterData.categoryList) ? (
                            <ul className="checkbox-list">
                              {filterData.categoryList.map(
                                (category, index) => (
                                  <li key={index} className="form-check">
                                    <input
                                      className="form-check-input categoryId"
                                      type="radio"
                                      defaultValue={category.categoryId}
                                      name="category"
                                      id={category.categoryName}
                                      onChange={handleChange}
                                      checked={
                                        category.categoryId === categoryValue
                                      }
                                    />
                                    <label
                                      className="form-check-label"
                                      htmlFor={category.categoryName}
                                    >
                                      {category.categoryName}
                                    </label>
                                  </li>
                                )
                              )}
                            </ul>
                          ) : (
                            ""
                          )}
                        </div>
                      </div>
                    ) : (
                      ""
                    )}

                    {filterData.departmentList &&
                    filterData.departmentList.length ? (
                      <div className="sidebar-widget-02">
                        <h3 className="widget-title">Department</h3>
                        <div className="widget-checkbox">
                          {Array.isArray(filterData.departmentList) ? (
                            <select
                              className="py-2 px-1"
                              style={{ fontSize: "14px" }}
                              id="department"
                              name="department"
                              value={selectedDepartment}
                              onChange={handleChange}
                            >
                              <option value="">All Departments</option>
                              {filterData.departmentList.map(
                                (department, index) => (
                                  <option
                                    key={index}
                                    value={department.departmentId}
                                  >
                                    {department.departmentName}
                                  </option>
                                )
                              )}
                            </select>
                          ) : (
                            ""
                          )}
                        </div>
                      </div>
                    ) : (
                      ""
                    )}

                    {filterData.courseList && filterData.courseList.length ? (
                      <div className="sidebar-widget-02">
                        <h3 className="widget-title">Course</h3>
                        <div className="widget-checkbox">
                          {Array.isArray(filterData.courseList) ? (
                            <select
                              className="py-2 px-1"
                              style={{ fontSize: "14px" }}
                              id="department"
                              name="department"
                            >
                              {filterData.courseList.map((course, index) => (
                                <option key={index} value={course.courseId}>
                                  {course.courseName}
                                </option>
                              ))}
                            </select>
                          ) : (
                            ""
                          )}
                        </div>
                      </div>
                    ) : (
                      ""
                    )}

                    <div className="sidebar-widget-02">
                      <h3 className="widget-title">Level</h3>

                      <div className="widget-checkbox">
                        <ul className="checkbox-list">
                          <li className="form-check">
                            <input
                              className="form-check-input level"
                              type="radio"
                              defaultValue="beginner"
                              id="beginner"
                              name="level"
                            />
                            <label
                              className="form-check-label"
                              htmlFor="beginner"
                            >
                              Beginner
                            </label>
                          </li>
                          <li className="form-check">
                            <input
                              className="form-check-input level"
                              type="radio"
                              defaultValue="intermediate"
                              id="intermediate"
                              name="level"
                            />
                            <label
                              className="form-check-label"
                              htmlFor="intermediate"
                            >
                              Intermediate
                            </label>
                          </li>
                          <li className="form-check">
                            <input
                              className="form-check-input level"
                              type="radio"
                              defaultValue="advanced"
                              id="advanced"
                              name="level"
                            />
                            <label
                              className="form-check-label"
                              htmlFor="advanced"
                            >
                              Advanced
                            </label>
                          </li>
                        </ul>
                      </div>
                    </div>

                    <div className="sidebar-widget-02">
                      <h3 className="widget-title">Type of Courses</h3>
                      <div className="widget-checkbox">
                        <ul className="checkbox-list">
                          <li className="form-check">
                            <input
                              className="form-check-input"
                              type="radio"
                              defaultValue="free"
                              id="free"
                              name="price-type"
                            />
                            <label className="form-check-label" htmlFor="free">
                              Free
                            </label>
                          </li>
                          <li className="form-check">
                            <input
                              className="form-check-input"
                              type="radio"
                              defaultValue="paid"
                              id="paid"
                              name="price-type"
                            />
                            <label className="form-check-label" htmlFor="paid">
                              Paid
                            </label>
                          </li>
                        </ul>
                      </div>
                    </div>

                    <Link
                      to={"/filter"}
                      className="btn btn-secondary btn-block"
                    >
                      Clear Filter
                    </Link>
                  </form>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};
export default Filter;
