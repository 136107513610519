import React, { useEffect, useState } from "react";
import OwlCarousel from "react-owl-carousel";
import "owl.carousel/dist/assets/owl.carousel.css";
import "owl.carousel/dist/assets/owl.theme.default.css";

const ComputingScreenComponent = (props) => {
  const [responsive, setResponsive] = useState({
    0: { items: 2 },
    450: { items: 2 },
    600: { items: 4 },
    1000: { items: 5 },
  });

  useEffect(() => {
    // Move data fetching code or side effects here if needed

    // Example: Update responsive based on data
    const updatedResponsive = {
      0: { items: 2 },
      450: { items: 2 },
      600: { items: 4 },
      1000: { items: 5 },
    };

    setResponsive(updatedResponsive);
  }, []); // Add dependencies if needed

  return (
    <>
      <section className="new-slider-section">
        <div className="container">
          <div className="section-title">
            <div className="row align-items-center justify-content-md-between">
              <div className="col-md-12">
                <h2 className="title">{props.data}</h2>
              </div>
            </div>
          </div>
        </div>
        <div className="container-fluid">
          <div className="row">
            <div className="col-md-12 p-0">
              <div className="new-slider-slider">
                <OwlCarousel                  
                  items={5}
                  className="owl-theme cloned"
                  loop                  
                  margin={50}
                  autoplay={true}
                  dots={false}
                  responsive={responsive}
                >
                  {props.dataList?(props.dataList.map((screens, index) => (
                  <div key={index} className="item">
                    <div className="new-slider-inner">
                      <img
                        className="lazyload"
                        src={screens.imagePath}
                        alt={screens.mobileWebSolutionId}
                      />
                    </div>
                  </div>                  
                  ))):''}
                  
                </OwlCarousel>
              </div>
            </div>
          </div>
        </div>
      </section>
    </>
  );
};

export default ComputingScreenComponent;
