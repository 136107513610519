import React from 'react'

const FeaturedPlanComponent = (props) => {
  return (
    <>
    <section className="signup-step-container">
        <div className="container">
          <div className="section-title">
            <div className="row align-items-center justify-content-md-between">
              <div className="col-md-12">
                <h2 className="title">Features</h2>
              </div>
            </div>
          </div>
          <div className="row d-flex justify-content-center">
            <div className="col-md-8">
              <div className="wizard">
                <div className="tab-content" id="main_form">
                  <div className="tab-pane active" role="tabpanel" id="step1">
                    <div className="table-responsive">
                      <table className="table-striped">
                        <thead>
                          <tr>
                            <th>Sr No.</th>
                            <th>Features</th>
                            <th>Availablity</th>
                          </tr>
                        </thead>
                        {props.data?(props.data.map((featured, index) => (
                        <tbody key={index}>
                            <tr>
                                <th colSpan="3" className="feature-title">
                                    {featured.featureTitle}                                    
                                </th>
                            </tr>                            
                            {featured.featuresList?(featured.featuresList.map((list, ind) => (
                              <tr key={ind+1}>
                                <td>{ind+1}</td>
                                <td>{list.featureName} </td>
                                <td>
                                    {list.availability==="no" ?
                                    <i className="fa fa-minus"></i>
                                    : <i className="fa fa-check"></i> }
                                </td>
                              </tr>
                            ))):''}
                        </tbody>
                        ))):''}                            
                      </table>
                    </div>
                  </div>
                  <div className="clearfix"></div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
    </>
  );
};

export default FeaturedPlanComponent
