import React from "react";
import { Link } from "react-router-dom";

const InfoBlock = ({ imgUrl, subtitle, title, description, buttonsOne, buttonsLinkOne, buttonsTwo, buttonsLinkTwo }) => {
  return (
    <>
      <section className="about-info gray-bg" id="about-info">
        <div className="container">
          <div className="wrapper-info-about">
            <div className="row align-items-center">
              <div className="col-md-5">
                <img src={imgUrl} alt={title} />
              </div>
              <div className="col-md-7">
                <div className="left-space">
                  <h6>{title}</h6>
                  <h2>{subtitle}</h2>                  
                  <div dangerouslySetInnerHTML={{ __html: description }}></div>
                  {buttonsOne?
                    <Link                        
                        className="btn btn-primary btn-hover-heading-color"
                        to={buttonsLinkOne}
                      >
                      {buttonsOne}
                    </Link>
                    :''}
                    {buttonsTwo?
                    <Link                        
                        className="btn btn-outline-primary enterprise-twobtn"
                        to={buttonsLinkTwo}
                      >
                      {buttonsTwo}
                    </Link>
                    :''}
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
    </>
  );
};

export default InfoBlock;
