import React, { useEffect } from "react";
import "./App.css";
import "bootstrap/dist/css/bootstrap.min.css";
import "./assets/website/fonts/font-awesome.min.css";
import "./assets/website/css/style.min.css";
import "./assets/website/css/style.css";
import "./assets/website/css/mobile.css";
import "./assets/website/css/chat.css";
import "./assets/website/css/price.css";
import "./assets/website/css/flaticon.css"
// import 'jquery/dist/jquery.min.js';
import "bootstrap/dist/js/bootstrap.bundle.min.js";
import {
  createBrowserRouter,
  RouterProvider,
  Navigate,
} from "react-router-dom";
import Home from "../src/pages/home/Home";
import AboutUs from "../src/pages/about-us/AboutUs";
import ForEnterprise from "../src/pages/for-enterprise/ForEnterprise";
import ForGovernment from "../src/pages/for-government/ForGovernment";
import HelsFaqs from "../src/pages/help-faqs/HelpFaqs";
import Blogs from "../src/pages/blogs/Blogs";
import NoticeBoard from "../src/pages/notice-board//NoticeBoard";
import Career from "../src/pages/career/Career";
import CareerDetails from "../src/pages/career/CareerDetails";
import ContactUs from "../src/pages/ContactUs";
import TermsAndCondition from "../src/pages/tnc/TermsAndCondition";
import BlogDetails from "../src/pages/blogs/BlogDetails";
import Cart from "../src/pages/Cart";
import Checkout from "../src/pages/Checkout";
import JobDetails from "../src/pages/career/JobDetails";
import Filter from "../src/pages/Filter";
import CourseDetails from "../src/pages/CourseDetails";
import DepartmentList from "../src/pages/DepartmentList";
import DashboardComponent from "../src/config/components/DashboardComponent";
import RefundPolicy from "../src/pages/refund-policy/RefundPolicy";
import Cookies from "../src/pages/cookies/Cookies";
import PrivacyPolicy from "../src/pages/privacy-policy/PrivacyPolicy";
import UserRouter from "./config/users/UserRouter";
import Instructor from "./pages/instructor/Instructor";
import DigitalPlanFilter from "./pages/instructor/DigitalPlanFilter";
import Student from "./pages/student/Student";
import { STUDENTLINK } from "./constants";

const router = createBrowserRouter([
  {
    path: "/",
    element: <UserRouter />,
    children: [
      { index: true, element: <Home /> },
      {
        index: true,
        path: "courses/:courseNameSlugUrl/:subjectNameSlugUrl",
        element: <CourseDetails />,
      },
      {
        // index: true,
        path: "filter",
        element: <Filter />,
      },
      {
        path: "about-us",
        element: <AboutUs />,
      },
      {
        // index: true,
        path: "for-enterprise",
        element: <ForEnterprise />,
      },
      {
        path: "for-government",
        element: <ForGovernment />,
      },
      {
        path: "blogs",
        element: <Blogs />,
      },
      {
        index: true,
        path: "blogs/:categoryTitle/:blogTitle",
        element: <BlogDetails />,
      },
      {
        path: "help-faqs",
        element: <HelsFaqs />,
      },
      {
        path: "notice-board",
        element: <NoticeBoard />,
      },
      {
        path: "career",
        element: <Career />,
      },
      {
        path: "jobs",
        element: <CareerDetails />,
      },
      {
        index: true,
        path: "jobs/:jobTitle",
        element: <JobDetails />,
      },
      {
        path: "contact-us",
        element: <ContactUs />,
      },
      {
        path: "cart",
        element: <Cart />,
      },
      {
        // index: true,
        path: "checkout",
        element: <Checkout />,
      },
      {
        path: "terms-and-conditions",
        element: <TermsAndCondition />,
      },
      {
        path: "privacy-policy",
        element: <PrivacyPolicy />,
      },
      {
        path: "refund-policy",
        element: <RefundPolicy />,
      },
      {
        path: "cookie-policy",
        element: <Cookies />,
      },
      {
        path: "instructor",
        element: <Instructor />,
      },
      {
        index: true,
        path: "department/:departmentNameSlugUrl",
        element: <DepartmentList />,
      },
      {
        path: "digital-plan-filter",
        element: <DigitalPlanFilter />,
      },
      {
        path: "user",
        element: <DashboardComponent />,
      },
      {
        path: "student",
        element: <Student />,
      },
      {
        path: "*", // 404 - Error Redirect to Homepage
        element: <Navigate to="/" />,
      },
    ],
  },
]);


function App() {
  
  useEffect(() => {
    window.onmessage = function(e) {
      if (e.origin !== STUDENTLINK) {
          return
      }
        
      const data = JSON.parse(e.data)
        
      if (typeof data.authUser !== "undefined") {
          localStorage.setItem("authUser", data.authUser)          
      }
    }
  //   const handleKeyDown = (e) => {
  //     // Prevent F12 and other developer tools shortcuts
  //     if (
  //       e.key === "F12" ||
  //       (e.ctrlKey && e.shiftKey && e.key === "I") ||
  //       (e.metaKey && e.altKey && e.key === "I") ||
  //       (e.ctrlKey && e.shiftKey && e.key === "C") ||
  //       (e.metaKey && e.altKey && e.key === "C") ||
  //       (e.ctrlKey && e.shiftKey && e.key === "J") ||
  //       (e.metaKey && e.altKey && e.key === "J") ||
  //       (e.ctrlKey && e.key === "U") ||
  //       (e.metaKey && e.altKey && e.key === "U")
  //     ) {
  //       e.preventDefault();
  //     }
  //   };

  //   document.addEventListener("keydown", handleKeyDown);

  //   // Cleanup function to remove the event listener when the component unmounts
  //   return () => {
  //     document.removeEventListener("keydown", handleKeyDown);
  //   };
  }, []);

  
  return (
    <div>
      <div className="main-wrapper">
        <RouterProvider router={router} />
        {/* <Router>
          <React.StrictMode>
            <NavbarComponent />
            <UserRoutes />
            <FooterComponent />
          </React.StrictMode>
        </Router> */}
      </div>
    </div>
  );
}

export default App;
