import React from "react";
import { Link } from "react-router-dom";

const TopDepartmentComponent = (props) => {
  return (
    <>
      <div className="section section-padding top-department">
        <div className="container">
          <div className="section-title text-center">
            <h2 className="title">
              <span>Top department</span> you can enroll now
            </h2>
          </div>
          <div className="category-wrapper">
          {props.data?(
            <div className="row">
              {props.data.map((departments, index) => (
                <div key={index} className="col-lg-3 col-sm-6 d-flex">
                  <div className="single-category flex-fill">
                    <div className="category-image">
                      <Link to={"/department/"+departments.departmentNameSlugUrl}>
                        <img
                          src={departments.topRatedDepartmentImage}
                          alt={departments.departmentName}
                        />
                      </Link>
                    </div>
                    <div className="category-content">
                      <div className="category-title">
                        <h4 className="title">
                          <Link to={"/department/"+departments.departmentNameSlugUrl}>
                            {departments.departmentName}
                          </Link>
                        </h4>
                        <p>{departments.totalCourse} Course</p>
                      </div>
                      <Link
                        to={"/department/"+departments.departmentNameSlugUrl}
                        className="category-link"
                        aria-label="category-link"
                      >
                        <i className="fa fa-long-arrow-right"></i>
                      </Link>
                    </div>
                  </div>
                </div>
              ))}
            </div>
          ):''}
          </div>
        </div>
      </div>
    </>
  );
};

export default TopDepartmentComponent;
