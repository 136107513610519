import React, { useState } from "react";
import "./pagination.css";

const Pagination = ({ totalPages, currentPage, onPageChange }) => {
  const [visiblePages, setVisiblePages] = useState(4);

  const handleNextClick = () => {
    onPageChange(currentPage + visiblePages + 1); // Move to the next set of pages
    setVisiblePages((prev) => prev + 4);
  };

  const handlePrevClick = () => {
    const newVisiblePages = Math.max(0, visiblePages - 4);
    onPageChange(currentPage - newVisiblePages - 1); // Move to the previous set of pages
    setVisiblePages(newVisiblePages);
  };

  return (
    <div className="page-pagination">
      <ul className="pagination justify-content-center">
        {currentPage > 1 && (
          <li>
            <button
              onClick={handlePrevClick}
            >
              <i className="fa fa-angle-left"></i>
            </button>
          </li>
        )}
        {Array.from({ length: Math.min(totalPages, visiblePages) }, (_, index) => (
          <li key={index}>
            <button
              onClick={() => onPageChange(index + currentPage)}
              className={currentPage === index + currentPage ? "active" : ""}
            >
              {index + currentPage}
            </button>
          </li>
        ))}
        {currentPage + visiblePages <= totalPages && (
          <li>
            <button onClick={handleNextClick}>
              <i className="fa fa-angle-right"></i>
            </button>
          </li>
        )}
      </ul>
    </div>
  );
};

export default Pagination;
