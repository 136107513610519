import React from "react";

const Checkout = () => {
  return (
    <>
      <div
        className="section page-banner-section bg-color-1 checkout-banner"
        style={{
          backgroundImage: `url(
            "https://www.matriyeacademy.com/website-assets/images/checkout-banner.png"
          )`,
        }}
      >
        <div className="container">
          <div className="course-details-banner-content">
            <h2 className="title">
              <p>
                Matriye Academy is chosen by top organizations and institutions
                to help them develop in-demand career skills.
              </p>
              <br />
              Your Cart
            </h2>
          </div>
        </div>
      </div>
      <section className="checkout-section mt-2">
        <div className="container">
          <div className="row">
            <div className="col-md-7">
              <form
                action="http://localhost/matriyeacademy/paytm/redirection"
                method="post"
                id="userCheckoutStore"
                name="userCheckoutStore"
                autoComplete="off"
                acceptCharset="utf-8"
                noValidate="novalidate"
              >
                <div className="checkout-outer">
                  <h3>Billing Information</h3>
                  <div className="address-section-main">
                    <div className="address-section">
                      <div className="address-content">
                        <h6> </h6>
                        <p> </p>
                      </div>
                      <div className="address-edit">
                        <i className="fa fa-times bg-danger"></i>
                        <a href="/" className="edit-pen">
                          Edit
                        </a>
                      </div>
                    </div>
                    <div className="address-form">
                      <p>
                        We use this information to register products, secure
                        your identity and calculate taxes and fees.{" "}
                      </p>
                      <p className="field-text">All feilds are required.</p>
                      <div className="row">
                        <div className="col-md-12 mb-3">
                          <div className="form-group">
                            <select
                              name="countryId"
                              id="countryId"
                              className="form-control"
                            >
                              <option defaultValue="">
                                --Select Country--
                              </option>
                              <option defaultValue="1">Afghanistan</option>
                            </select>
                          </div>
                        </div>
                        <div className="col-md-6 mb-3">
                          <input
                            type="text"
                            name="firstName"
                            maxLength="15"
                            id="firstName"
                            placeholder="First name"
                            className="form-control isAlpha"
                            defaultValue=""
                          />
                        </div>
                        <div className="col-md-6 mb-3">
                          <input
                            type="text"
                            name="lastName"
                            maxLength="15"
                            id="lastName"
                            placeholder="Last name"
                            className="form-control isAlpha"
                            defaultValue=""
                          />
                        </div>
                        <div className="col-md-12 mb-3">
                          <div className="mobile-address">
                            <div>
                              <button
                                className="btn btn-secondary"
                                type="button"
                                id="dropdownMenuButton"
                                data-toggle="dropdown"
                                aria-haspopup="true"
                                aria-expanded="false"
                              >
                                <img
                                  src="https://www.worldometers.info/img/flags/small/tn_in-flag.gif"
                                  alt="flg"
                                />
                                +91
                              </button>
                            </div>
                            <input
                              type="text"
                              name="mobileNumber"
                              placeholder="Enter Mobile Number"
                              className="isInteger mobile-checkout"
                              maxLength="10"
                              minLength="10"
                              defaultValue=""
                            />
                          </div>
                          <div className="mobile-address-check">
                            <p>
                              Used to validate product and to validate your
                              identity
                            </p>
                            <div className="form-check">
                              <input
                                className="form-check-input"
                                type="checkbox"
                                id="check2"
                                name="option2"
                                defaultValue="something"
                              />
                              Notify me on whatsapp about renewals and special
                              offers
                            </div>
                          </div>
                        </div>
                        <div className="col-md-12 mb-3">
                          <input
                            type="text"
                            name="apartmentName"
                            id="apartmentName"
                            maxLength="50"
                            placeholder="Building/ Society"
                            className=" form-control"
                            defaultValue=""
                          />
                        </div>
                        <div className="col-md-12 mb-3">
                          <input
                            type="text"
                            name="streetAddress"
                            id="streetAddress"
                            placeholder="Street address"
                            maxLength="225"
                            className=" form-control"
                            defaultValue=""
                          />
                        </div>
                        <div className="col-md-6 mb-3">
                          <input
                            type="text"
                            name="postcode"
                            id="postcode"
                            placeholder="Pin Code*"
                            maxLength="6"
                            minLength="6"
                            className="isInteger form-control"
                            defaultValue=""
                          />
                        </div>
                        <div className="col-md-6 mb-3">
                          <div className="form-group">
                            <select
                              name="stateId"
                              id="stateId"
                              className="form-control"
                            >
                              <option defaultValue="">--Select State--</option>
                            </select>
                          </div>
                        </div>
                        <div className="col-md-12 mb-3">
                          <input
                            type="text"
                            name="cityName"
                            id="cityName"
                            maxLength="15"
                            placeholder="Town / City*"
                            className="form-control isAlpha"
                            defaultValue=""
                          />
                        </div>
                        <div className="col-md-12 mb-3">
                          <input
                            type="text"
                            name="organizationName"
                            id="organizationName"
                            placeholder="Organization Name"
                            className="form-control isAlpha"
                            defaultValue=""
                          />
                        </div>
                        <div className="col-md-12 mb-3">
                          <input
                            type="text"
                            name="gstinNumber"
                            id="gstinNumber"
                            placeholder="GSTIN"
                            className="form-control"
                            defaultValue=""
                          />
                        </div>

                        <input
                          type="hidden"
                          id="ORDER_ID"
                          tabIndex="1"
                          maxLength="20"
                          size="20"
                          name="ORDER_ID"
                          autoComplete="off"
                          className="form-control"
                          defaultValue="MAC1687861563"
                        />
                        <input
                          type="hidden"
                          id="CUST_ID"
                          tabIndex="2"
                          maxLength="12"
                          size="12"
                          name="CUST_ID"
                          autoComplete="off"
                          className="form-control"
                          defaultValue="CUST1687861563"
                        />
                        <input
                          type="hidden"
                          id="INDUSTRY_TYPE_ID"
                          tabIndex="4"
                          maxLength="12"
                          size="12"
                          name="INDUSTRY_TYPE_ID"
                          autoComplete="off"
                          defaultValue="Retail"
                        />
                        <input
                          type="hidden"
                          id="CHANNEL_ID"
                          tabIndex="4"
                          maxLength="12"
                          size="12"
                          name="CHANNEL_ID"
                          autoComplete="off"
                          defaultValue="WEB"
                        />
                        <input
                          type="hidden"
                          title="TXN_AMOUNT"
                          tabIndex="10"
                          name="TXN_AMOUNT"
                          defaultValue="12,999.00"
                        />
                        <input
                          type="hidden"
                          name="email_id"
                          id="email_id"
                          defaultValue=""
                        />
                        <input
                          type="hidden"
                          name="mobile_no"
                          id="mobile_no"
                          defaultValue="9877878787"
                        />
                        <input
                          type="hidden"
                          id="udf5"
                          name="udf5"
                          defaultValue="PayUBiz_PHP7_Kit"
                        />
                        <input
                          type="hidden"
                          id="surl"
                          name="surl"
                          defaultValue="http://localhost/matriyeacademy/payumoney/redirection"
                        />
                        <input
                          type="hidden"
                          id="furl"
                          name="furl"
                          defaultValue="http://localhost/matriyeacademy/payumoney/redirection"
                        />
                        <input
                          type="hidden"
                          id="curl"
                          name="curl"
                          defaultValue="http://localhost/matriyeacademy/payumoney/redirection"
                        />
                        <input
                          type="hidden"
                          id="key"
                          name="key"
                          defaultValue="gtKFFx"
                        />
                        <input
                          type="hidden"
                          id="txnid"
                          name="txnid"
                          defaultValue="MAC1687861563"
                        />
                        <input
                          type="hidden"
                          id="amount"
                          name="amount"
                          defaultValue="12,999.00"
                        />
                        <input
                          type="hidden"
                          id="email"
                          name="email"
                          defaultValue=""
                        />
                        <input
                          type="hidden"
                          id="productinfo"
                          name="productinfo"
                          defaultValue="Course Purchase"
                        />
                        <input
                          type="hidden"
                          id="firstname"
                          name="firstname"
                          defaultValue=""
                        />
                        <input
                          type="hidden"
                          id="hash"
                          name="hash"
                          defaultValue=""
                        />
                      </div>
                    </div>
                  </div>

                  <div className="payment-section">
                    <h3>Payment Method</h3>
                    <hr />
                    <div className="method-list1">
                      <div className="comment-form-consent input-box">
                        <input
                          id="radio-1"
                          type="radio"
                          name="selector"
                          defaultValue="Paytm"
                          defaultChecked=""
                        />
                        <label htmlFor="radio-1">
                          <img
                            src="https://www.matriyeacademy.com/website-assets/images/paytm-icon.svg"
                            alt=""
                            className="paytm-logo"
                          />
                        </label>
                      </div>
                      {/* <div className="comment-form-consent input-box">
                        <input
                          id="eazypay"
                          type="radio"
                          name="selector"
                          defaultValue="EasyPay"
                        />
                        <label htmlFor="eazypay">
                          <img
                            src="http://localhost/matriyeacademy/website-assets/images/Eazypay-logo-02.webp"
                            alt=""
                            className="eazypay-logo"
                          />
                        </label>
                      </div> */}
                    </div>
                    <div className="row">
                      <div className="col-md-6 mb-3">
                        <button type="submit" className="save-btn submit">
                          Make Payment
                        </button>
                      </div>
                      <div className="col-md-6 mb-3">
                        <a
                          className="cancel-btn"
                          href="http://localhost/matriyeacademy/cart/clear-cart"
                        >
                          Clear <i className="fa fa-trash-o"></i>
                        </a>
                      </div>
                    </div>
                  </div>
                </div>
              </form>
            </div>

            <div className="col-md-5 mt-5">
              <div className="checkout-summary">
                <h3>Order Summary</h3>
                <div className="checkout-item">
                  <p>1 Item</p>
                  <a href="http://localhost/matriyeacademy/cart">Edit</a>
                </div>
                <hr />
                <div className="disclamers">
                  <a href="/">View offer disclamers</a>
                  <div className="checkout-subtotal">
                    <h6>Subtotal</h6>
                    <p>
                      <i className="fa fa-inr"></i>12,999.00
                    </p>
                  </div>
                  <div className="checkout-gst">
                    <h6>Included GST 18% </h6>
                  </div>

                  <div className="checkout-promo">
                    <h6>
                      Promo Code <span>-</span>
                    </h6>
                  </div>
                  <p className="valid-promo">
                    <i className="fa fa-check"></i> <b>Valid promo code</b>{" "}
                    You're getting the best price we have got.
                  </p>
                  <hr />
                  <div className="checkout-total">
                    <h6>Total (INR)</h6>
                    <p>
                      <i className="fa fa-inr"></i>12,999.00
                    </p>
                  </div>
                </div>
              </div>

              <div className="secure-checkout">
                <h6>
                  <i className="fa fa-lock"></i>Secure Connect
                </h6>
              </div>

              <div className="checkout-items-list">
                <div
                  className="panel-group accordion"
                  id="another"
                  role="tablist"
                  aria-multiselectable="true"
                >
                  <div className="panel panel-default">
                    <div className="panel-heading" role="tab" id="accordion-i1">
                      <h4 className="panel-title">
                        <a
                          className="collapsed"
                          role="button"
                          data-toggle="collapse"
                          data-parent="#another"
                          href="#accordion-pane-i1"
                          aria-expanded="false"
                        >
                          Your Items (1)
                          <span className="plus-minus">
                            <span></span>
                          </span>
                        </a>
                      </h4>
                    </div>
                    <div
                      id="accordion-pane-i1"
                      className="panel-collapse collapse"
                      aria-labelledby="accordion-i1"
                      aria-expanded="false"
                    >
                      <div className="panel-body">
                        <div className="checkout-items-list-content">
                          <div className="checkout-items-list-content-left">
                            <h6>History </h6>
                            <p>
                              <a
                                href="http://localhost/matriyeacademy/cart/delete/cart/28c8edde3d61a0411511d3b1866f0636"
                                className="text-danger"
                              >
                                <i className="fa fa-trash close-summary text-danger"></i>
                              </a>
                            </p>
                          </div>
                          <div className="summary-right">
                            <h5 className="summary-price">
                              <i className="fa fa-inr"></i>12,999.00{" "}
                            </h5>
                            <p className="summary-cut">
                              <i className="fa fa-inr"></i>0.00{" "}
                            </p>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
    </>
  );
};

export default Checkout;
