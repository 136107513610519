import React from "react";
import BannerComponent from "../../components/page-wise-components/home/BannerComponent";
import HomeCountUpComponent from "../../components/page-wise-components/home/HomeCountUpComponent";
import TopRatedCoursesComponent from "../../components/page-wise-components/home/TopRatedCoursesComponent";
import TopDepartmentComponent from "../../components/page-wise-components/home/TopDepartmentComponent";
import FeaturedTopicComponent from "../../components/page-wise-components/home/FeaturedTopicComponent";
import ComputingScreenComponent from "../../components/page-wise-components/home/ComputingScreenComponent";
import BecomeWithComponent from "../../components/page-wise-components/home/BecomeWithComponent";
import AppSectionComponent from "../../components/page-wise-components/home/AppSectionComponent";
import FeatureSectionComponent from "../../components/page-wise-components/home/FeatureSectionComponent";
import TestimonialBlockComponent from "../../components/page-wise-components/home/TestimonialBlockComponent";
import ThreeBoxLayout from "../../components/core/three-box-layout/ThreeBoxLayout";
import InfoBlock from "../../components/core/info-block/InfoBlock";
import axios from "axios";
import { useQuery } from "react-query";
import { Helmet } from "react-helmet";
import { SITETITLE } from "../../constants";

const getBanner = async () => {
  const response = await axios.get(window.apiBaseUrl+'home/banner');  
  return response.data.data;
};

const getCounterData = async () => {
  const response = await axios.get(window.apiBaseUrl+'home/counter');  
  return response.data.data;
};

const getOurDepartmentData = async () => {
  const response = await axios.get(window.apiBaseUrl+'home/our-departments');  
  return response.data.data;
};

const getTopDepartmentData = async () => {
  const response = await axios.get(window.apiBaseUrl+'home/get-top-departments');  
  return response.data.data;
};

const getFeaturedTopicData = async () => {
  const response = await axios.get(window.apiBaseUrl+'home/get-featured-topics');  
  return response.data.data;
};

const getComputerScreenData = async () => {
  const response = await axios.get(window.apiBaseUrl+'home/complete-mobile-web-solutions');  
  return response.data.data;
};

const getBecomeWithData = async () => {
  const response = await axios.get(window.apiBaseUrl+'home/what-you-can-become-with-matriye');  
  return response.data.data;
};

const getDownloadAppData = async () => {
  const response = await axios.get(window.apiBaseUrl+'home/download-apps');  
  return response.data.data;
};

const getFeaturedInData = async () => {
  const response = await axios.get(window.apiBaseUrl+'home/featured-in');  
  return response.data.data;
};

const getInfoSectionData = async () => {
  const response = await axios.get(window.apiBaseUrl+'home/become-an-instructor');  
  return response.data.data;
};

const getTestimonialData = async () => {
  const response = await axios.get(window.apiBaseUrl+'home/testimonials');  
  return response.data.data;
};

const getMetaData = async () => {
  const response = await axios.get(window.apiBaseUrl+'home/meta-details');  
  return response.data.data;
};

const Home = () => {
  const { data: banner, error, isLoading } = useQuery("bannerData", getBanner);    
  const { data: meta } = useQuery("metaData", getMetaData);    
  const { data: counter } = useQuery("counterData", getCounterData);    
  const { data: ourDepartments } = useQuery("ourDepartments", getOurDepartmentData);      
  const { data: topDepartment } = useQuery("topDepartment", getTopDepartmentData);    
  const { data: featuredTopic } = useQuery("featuredTopic", getFeaturedTopicData);    
  const { data: computerScreen } = useQuery("computerScreen", getComputerScreenData);    
  const { data: whatCanBecomeWith } = useQuery("canBecomeWith", getBecomeWithData);    
  const { data: downloadApp } = useQuery("downloadApp", getDownloadAppData);
  const { data: featuredIn } = useQuery("featuredIn", getFeaturedInData);    
  const { data: infoSection} = useQuery("infoSection", getInfoSectionData);
  const { data: testimonial} = useQuery("testimonial", getTestimonialData);
  const metaData = meta;
  const bannerData = banner;
  const counterData = counter;  
  const ourDepartmentData = ourDepartments;    
  const topDepartmentData = topDepartment;  
  const featuredTopicData = featuredTopic;  
  const computerScreenData = computerScreen;  
  const whatCanBecomeWithData = whatCanBecomeWith;  
  const downloadAppData = downloadApp;  
  const featuredInData = featuredIn;  
  const infoBlockData = infoSection;
  const testimonialData = testimonial;

  if (isLoading) return <div>Fetching posts...</div>;
  if (error) return <div>An error occurred: {error.message}</div>;

  return (
    <>
      <Helmet>          
          <title>{metaData?(metaData.metaTitle):SITETITLE}</title>          
          <meta name='description' content={metaData?(metaData.metaDescription):SITETITLE} />
          <meta name='keywords' content={metaData?(metaData.metaKeywords):SITETITLE} />
      </Helmet>
      {bannerData?
      <BannerComponent data={bannerData}/>
      :''}
      {counterData?
      <HomeCountUpComponent data={counterData}/>
      :''}
      {ourDepartmentData?
      <ThreeBoxLayout
        backgroundImage="https://www.matriyeacademy.com/website-assets/images/home-our-department-bg.webp"
        pageTitle={"Our Departments"}
        pageSubTitle={"Learn In-demand Skills"}
        boxContent={ourDepartmentData}
      />
      :''}
      <TopRatedCoursesComponent />      
      {topDepartmentData?
      <TopDepartmentComponent data={topDepartmentData}/>
      :''}
      {featuredTopicData?
      <FeaturedTopicComponent data={featuredTopicData}/>
      :''}
      {computerScreenData?
      <ComputingScreenComponent data={computerScreenData.mobileWebSolutionHeading} dataList={computerScreen.imageList}/>
      :''}
      {whatCanBecomeWithData?
      <BecomeWithComponent data={whatCanBecomeWithData.whatYouCanBecomeHeading} dataList={whatCanBecomeWithData.positionList}/>
      :''}
      {downloadAppData?
      <AppSectionComponent data={downloadAppData}/>      
      :''}
      {featuredInData?
      <FeatureSectionComponent data={featuredInData.featuredInHeading} dataList={featuredInData.featureList} />
      :''}
      {infoBlockData?
      <InfoBlock
        imgUrl={infoBlockData.image}
        subtitle={infoBlockData.subHeading}
        title={infoBlockData.heading}
        description={infoBlockData.description}
        buttonsOne={infoBlockData.buttonTextOne}
        buttonsLinkOne='instructor'
        // buttonsLinkOne={infoBlockData.buttonLinkOne}
      />
      :''}
      {testimonialData?
      <TestimonialBlockComponent data={testimonialData.staticData} dataList={testimonialData.testimonialList} />
      :''}
    </>
  );
};

export default Home;
