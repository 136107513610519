import React from "react";
import logo from "../../assets/website/image/logo.png";
import logomob from "../../assets/website/image/logo-icon.svg";
import categorylogo from "../../assets/website/image/category-icon.svg";
import shoppingBagLogo from "../../assets/website/image/shopping-bag.png";
import googlePlayLogo from "../../assets/website/image/google-play.png";
import appStoreLogo from "../../assets/website/image/app-store.png";
import { Link, useLocation } from "react-router-dom";
import { useCart } from "react-use-cart";
import { STUDENTLINK } from "../../constants";
import profileLogo from '../../assets/website/image/avatar-02.jpg';

function NavbarComponent(props) {
  
  const { isEmpty, totalUniqueItems, items, cartTotal, removeItem, emptyCart } = useCart();

  const location = useLocation();
  const isEnterpriseActive = location.pathname === "/for-enterprise";
  const isGovernmentActive = location.pathname === "/for-government";
  const isAboutUsActive = location.pathname === "/about-us";
  const isContactUsActive = location.pathname === "/contact-us";
  const isBlogsActive = location.pathname === "/blogs";

  return (
    <>
      {/* Header Start */}
      <div id="header" className="header section">
        <div className="container">
          {/* Header Wrapper Start */}
          <div className="header-wrapper">
            {/* Header Logo Start */}
            <div className="header-logo">
              <Link to="/">
                <img src={logo} alt="logo" className="desktop-logo" />
                <img src={logomob} alt="logoicon" className="desktop-mobile" />
              </Link>
              <div className="header-menu d-lg-block">
                <ul className="main-menu result">
                  <li>
                    <div className="arrow-icon">
                      <Link className="active main-linking" to="/">
                        <img src={categorylogo} alt="Cart" /> Explore Our
                        Courses
                        <span className="menu-icon"></span>
                      </Link>
                    </div>
                    <ul className="sub-menu sub-menu-main">
                      <h6>Goals</h6>
                      <li>
                        <div className="arrow-icon">
                          <a className="active exploreSideMenu" id="0" href="/">
                            Take a free course
                          </a>
                          <span className="menu-icon"></span>
                        </div>
                        <ul
                          className="sub-menu exploreSubMenu_0"
                          id="exploreSubMenu_0"
                        >
                          <li>
                            <h4>Advance your career</h4>
                            <p>
                              In 3-9 months, gain the skills to break into a new
                              career or take your career to the next level.
                            </p>
                            <div className="row">
                              <div className="col-md-6">
                                <h5>12 Math Course</h5>
                                <div className="uni-wrapper">
                                  <img src="/" alt="univariety icon" />
                                  <div className="block-uni">
                                    <h3>Adv Math Course</h3>
                                  </div>
                                </div>
                                <Link to="/" className="view-all-menu">
                                  View all <span className="menu-icon"></span>
                                </Link>
                              </div>
                            </div>
                          </li>
                        </ul>
                      </li>
                    </ul>
                  </li>
                </ul>
              </div>
              <div className="hover-div-menu"></div>
            </div>
            {/* Header Logo End */}
            {/* Header Meta Start */}
            <div className="header-meta">
              <div className="header-menu d-none d-lg-block">
                <ul className="main-menu">
                  <li>
                    <Link
                      to="/for-enterprise"
                      className={isEnterpriseActive ? "active" : ""}
                    >
                      For Enterprise
                    </Link>
                  </li>
                  <li>
                    <Link
                      to="/for-government"
                      className={isGovernmentActive ? "active" : ""}
                    >
                      For Government
                    </Link>
                  </li>
                  <li className="select-lang">
                    <a className="link btn btn-primary" href="/">
                      <i className="fa fa-globe" aria-hidden="true"></i>
                    </a>
                  </li>
                </ul>
              </div>
              <div className="header-cart dropdown">
                <button className="cart-btn" data-bs-toggle="dropdown">
                  <img
                    src={shoppingBagLogo}
                    className="shopping"
                    alt="shopping bag"
                  />
                  <span className="count">{totalUniqueItems}</span>
                </button>
                <div className="dropdown-menu dropdown-cart">
                  {isEmpty?(
                    <>
                    <div className="cart-total">
                      <span className="label">Your cart is empty</span>
                    </div>                     
                    </>                   
                  ):(
                    <>
                      <div  className="cart-items">                      
                    {items.map(item => (
                        <div key={item.id} className="single-cart-item">
                          <div className="item-image">
                            <img src={item.image} alt="Cart" loading="lazy" />
                          </div>
                          <div className="item-content">
                            <h4 className="title">
                              <Link to="/">
                                {item.subjectName}<br/>
                                <small>{item.courseName}</small>
                              </Link>
                            </h4>                                
                            <span className="quantity"> Rs. {item.price}</span>
                          </div>
                          <Link to="/" className="text-danger">
                            <button className="btn-close" onClick={() => removeItem(item.id)}></button>
                          </Link>
                        </div>                      
                    ))}
                      </div>  
                    <div className="cart-total">
                      <span className="label">Subtotal:</span>
                      <span className="">{cartTotal}</span>
                    </div>
                    <div className="cart-btns">
                      <Link
                        to="/cart"
                        className="btn btn-white btn-hover-primary"
                      >
                        Cart Details
                      </Link>
                      <button
                        onClick={emptyCart}
                        className="btn btn-white btn-hover-danger"
                      >
                        <i className="fa fa-trash"></i>&nbsp;
                        Clear Cart
                      </button>
                    </div> 
                    </>        
                  )}
                </div>
              </div>
              {localStorage.getItem("authUser")?(
              <div className="header-login d-lg-flex">
                <div className="dropdown">
                  <button className="dropdown-toggle author" type="button" id="dropdownMenuButton" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
                    <img src={profileLogo} className="userlogo" alt="user-profile" />
                  </button>
                  <div className="dropdown-menu" aria-labelledby="dropdownMenuButton">
                      <div className="user-profile-wrap">
                          <div className="img-wrapp">F</div>
                          <div className="name-text-wrapp">
                              <span>Full Name</span>
                              <div className="email-text-wrapp">example@gmail.com</div>
                          </div>
                      </div>
                      <ul>
                          <li><Link to="/">My Dashboard</Link></li>
                          <li><Link to="/">My Documents</Link></li>
                          <li><Link to="/">My Courses</Link></li>
                          <li><Link to="/">Notifications</Link>
                          </li>
                      </ul>
                      <ul>
                          <li><Link to="/chats">Messages</Link></li>                                                
                          <li><Link to="/purchase-history">Payment History</Link></li>
                      </ul>
                      <ul>
                          <li><Link to="/profile">Public profile</Link></li>
                          <li><Link to="/help-faqs">Help</Link></li>
                          <li><Link to="/logout">Log Out</Link></li>
                      </ul>
                  </div>
                </div>
              </div>
              ):(              
              <div className="header-login d-lg-flex desktop-login">
                <Link to={STUDENTLINK}>
                  <button className="link">
                    <span className="material-symbols-outlined lardge-dev">
                      account_circle
                    </span>
                    <span className=""> &nbsp;Login/Sign Up</span>
                  </button>
                </Link>                
              </div>
              )}

              <div className="header-toggle d-lg-none">
                <button
                  className="btn btn-primary"
                  type="button"
                  data-bs-toggle="offcanvas"
                  data-bs-target="#offcanvasWithBothOptions"
                  aria-controls="offcanvasWithBothOptions"
                >
                  <span></span>
                  <span></span>
                  <span></span>
                </button>
              </div>
            </div>
            {/* Header Meta End */}
          </div>
        </div>
      </div>
      {/* Header End */}
      {/*  */}
      {/* Offcanvas Start */}
      <div
        className="offcanvas offcanvas-start"
        data-bs-scroll="true"
        tabIndex="-1"
        id="offcanvasWithBothOptions"
        aria-labelledby="offcanvasWithBothOptionsLabel"
      >
        <div className="offcanvas-header">
          {/* Offcanvas Logo Start */}
          <div className="offcanvas-logo">
            <Link to="/">
              <img src={logo} alt="logo" />
            </Link>
          </div>
          {/* Offcanvas Logo End */}
          <button className="btn-close" data-bs-dismiss="offcanvas"></button>
        </div>
        <div className="offcanvas-body">
          <div className="header-search d-lg-block mb-2">
            <form action="/" method="get">
              <input
                type="text"
                name="search-courses"
                placeholder="Search Courses"
              />
              <button type="submit">
                <i className="flaticon-loupe"></i>
              </button>
            </form>
          </div>
          <div className="offcanvas-menu">
            <ul className="main-menu">
              <li>
                <Link
                  to="/for-enterprise"
                  className={isEnterpriseActive ? "active" : ""}
                >
                  For Enterprise
                </Link>
              </li>
              <li>
                <Link
                  to="/for-government"
                  className={isGovernmentActive ? "active" : ""}
                >
                  For Government
                </Link>
              </li>
              <li>
                <Link
                  to="/about-us"
                  className={isAboutUsActive ? "active" : ""}
                >
                  About Us
                </Link>
              </li>
              <li>
                <Link
                  to="/contact-us"
                  className={isContactUsActive ? "active" : ""}
                >
                  Contact Us
                </Link>
              </li>
              <li>
                <Link to="/blogs" className={isBlogsActive ? "active" : ""}>
                  Blogs
                </Link>
              </li>
            </ul>
            <div className="app-download-offer">
              <Link to="/">
                <img src={googlePlayLogo} alt="google-play-store" />
              </Link>
              <Link to="/">
                <img src={appStoreLogo} alt="app-store" />
              </Link>
              <div className="app-download"></div>
            </div>
          </div>
        </div>
      </div>
      {/* Offcanvas End */}
    </>
  );
  // }
}

export default NavbarComponent;
