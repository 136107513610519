import React, { useEffect, useState } from "react";
import ThreeBoxLayout from "../../components/core/three-box-layout/ThreeBoxLayout";
import KeyFeatures from "../../components/core/key-features/KeyFeatures";
import HeaderBanner from "../../components/core/header-banner/HeaderBanner";
// import AboutUsTab from "../../components/page-wise-components/about-us/AboutUsTab";
import AboutUsInfoBlock from "../../components/page-wise-components/about-us/AboutUsInfoBlock";
import AboutUsCeoInfo from "../../components/page-wise-components/about-us/AboutUsCeoInfo";
import axios from "axios";
import { useQuery } from "react-query";
import { Helmet } from "react-helmet";
import { SITETITLE } from "../../constants";

const getBanner = async () => {
  const response = await axios.get(window.apiBaseUrl+'about-us/banner');  
  return response.data.data;
};

const getAboutInfoData = async () => {
  const response = await axios.get(window.apiBaseUrl+'about-us/about');  
  return response.data.data;
};

const getStoryMissionData = async () => {
  const response = await axios.get(window.apiBaseUrl+'about-us/story-mission-vision');  
  return response.data.data;
};

const getWhyChooseUsData = async () => {
  const response = await axios.get(window.apiBaseUrl+'about-us/why-choose-us');  
  return response.data.data;
};

const getMetaData = async () => {
  const response = await axios.get(window.apiBaseUrl+'about-us/meta-details');  
  return response.data.data;
};

const AboutUs = () => {
  const { data: banner, error, isLoading } = useQuery("bannerData", getBanner);    
  const { data: meta } = useQuery("metaData", getMetaData);    
  const { data: aboutInfoData} = useQuery("aboutInfo", getAboutInfoData);    
  const { data: storyMissionData} = useQuery("storyMission", getStoryMissionData);    
  const { data: whyChooseUsData} = useQuery("getWhyChooseUsData", getWhyChooseUsData);    
  const metaData = meta;
  const headerBannerData = banner;    
  const aboutInfo = aboutInfoData;    
  const storyMission = storyMissionData;    
  const whyChooseUs = whyChooseUsData;  

  const [isSticky, setIsSticky] = useState(false);

  useEffect(() => {
    const handleScroll = () => {
      const scrollPosition = window.scrollY;
      const targetElement = document.getElementById('scrollTriggerId');

      if (targetElement) {
        const targetPosition = targetElement.offsetTop;
        const isStickyNow = scrollPosition >= targetPosition;

        setIsSticky(isStickyNow);
      }
    };

    window.addEventListener('scroll', handleScroll);

    // Clean up the event listener on component unmount
    return () => {
      window.removeEventListener('scroll', handleScroll);
    };
  }, []);

  const stickyClass = isSticky ? 'sticky-bar-comm sticky-bar-sticky' : 'sticky-bar-comm';
  
  if (isLoading) return <div>Fetching posts...</div>;
  if (error) return <div>An error occurred: {error.message}</div>;

  return (
    <>
      <Helmet>          
          <title>{metaData?(metaData.metaTitle):SITETITLE}</title>          
          <meta name='description' content={metaData?(metaData.metaDescription):SITETITLE} />
          <meta name='keywords' content={metaData?(metaData.metaKeywords):SITETITLE} />
      </Helmet>
    {headerBannerData?(
      <HeaderBanner
        imgUrl={headerBannerData.bannerImage}
        subtitle={headerBannerData.bannerSubHeading}
        title={headerBannerData.bannerHeading}
        description={headerBannerData.bannerSubHeading}
        buttonsOne={headerBannerData.buttonNameOne}
        buttonsLinkOne={headerBannerData.buttonLinkOne}
        buttonsTwo={headerBannerData.buttonNameTwo}
        buttonsLinkTwo={headerBannerData.buttonLinkTwo}
      />      
      ):''}

      <div className="main_newblock about-new-top ">
        <div className={stickyClass}>
          <div className="container">
            <ul>
              <li>
                <a href="#about-infos">About Matriye</a>
              </li>
              <li>
                <a href="#our-mission">We’ll get right to the point</a>
              </li>
              <li>
                <a href="#why-choose">Why choose us</a>
              </li>
              <li>
                <a href="#our-ceo">Meet Our CEO</a>
              </li>
            </ul>
          </div>
        </div>
      </div>

      {aboutInfo?(
      <AboutUsInfoBlock data={aboutInfo} customId="scrollTriggerId"/>
      ):''}
      {storyMission?(
      <ThreeBoxLayout
        customId="our-mission"
        backgroundImage="https://www.matriyeacademy.com/website-assets/images/mission-vision-story.png"
        pageTitle={storyMission.staticData.storyMissionVisionSectionHeading}
        pageSubTitle={storyMission.staticData.storyMissionVisionSectionSubHeading}
        boxContent={storyMission.sectionList}
      />
      ):''}
      {whyChooseUs?(
      <KeyFeatures
        customId="why-choose"
        pageTitle={storyMission?(storyMission.staticData.metaHeadingTwo):''}
        content={whyChooseUs}
        customClass="col-md-3"
      />
      ):''}
      <AboutUsCeoInfo />
    </>
  );
};

export default AboutUs;
