import * as Yup from 'yup';

const MAX_FILE_SIZE = 102400; //100KB

const validFileExtensions = { image: ['jpg', 'gif', 'png', 'jpeg', 'svg', 'webp'], pdf: ['pdf'] };

function isValidFileType(fileName, fileType) {
  return fileName && validFileExtensions[fileType].indexOf(fileName.split('.').pop()) > -1;
}

export const forEnterprise = Yup.object({
    firstName: Yup.string().min(2).max(25).required("Please enter your first name"),
    lastName: Yup.string().min(2).max(25).required("Please enter your last name"),
    companyName: Yup.string().min(2).max(50).required("Please enter your company name"),
    phone: Yup.string().matches(/^[0-9]{10}$/, 'mobile number must be 10 digits').required("Please enter your mobile number"),
    workingEmailAddress: Yup.string().email().required("Please enter your working email address"),
    govtNonProfitAgency: Yup.string().required("Please select agency"),
    companySize: Yup.string().required("Please select company size"),
    expectedLearner: Yup.string().required("Please select expected number of learner"),
    jobRole: Yup.string().required("Please select job role"),
    jobFunction: Yup.string().required("Please select job function"),
    countryName: Yup.string().required("Please select country name"),    
    message: Yup.string().min(5).max(100).required("Please enter message"), 
});

export const forGovernment = Yup.object({
    firstName: Yup.string().min(2).max(25).required("Please enter your first name"),
    lastName: Yup.string().min(2).max(25).required("Please enter your last name"),
    jobTitle: Yup.string().min(2).max(50).required("Please enter your job title"),
    email: Yup.string().email().required("Please enter your working email address"),
    phone: Yup.string().matches(/^[0-9]{10}$/, 'mobile number must be 10 digits').required("Please enter your mobile number"),
    concernPersonTitle: Yup.string().min(2).max(50).required("Please enter your organization name"),
    interestedIn: Yup.string().required("Please select interested in training"),
    numberLearner: Yup.string().required("Please select expected number of learner"),
    countryName: Yup.string().required("Please select country name"),    
    message: Yup.string().min(5).max(100).required("Please enter message"), 
});

export const forContactUs = Yup.object({
    name: Yup.string().min(2).max(25).required("Please enter your name"),    
    email: Yup.string().email().required("Please enter your working email address"),
    phone: Yup.string().matches(/^[0-9]{10}$/, 'mobile number must be 10 digits').required("Please enter your mobile number"),
    message: Yup.string().min(5).max(100).required("Please enter message"), 
});

export const forApplyJobPost = Yup.object({
    jobId: Yup.number().required("Please select job id"),    
    designation: Yup.string().min(2).required("Please select job title"),    
    fullName: Yup.string().min(2).max(25).required("Please enter your name"),    
    emailId: Yup.string().email().required("Please enter your email address"),
    mobileNumber: Yup.string().matches(/^[0-9]{10}$/, 'mobile number must be 10 digits').required("Please enter your mobile number"),
    qualification: Yup.string().min(5).max(100).required("Please enter qualification"), 
    stateId: Yup.string().min(5).max(100).required("Please select your state"), 
    cityId: Yup.string().min(5).max(100).required("Please select your city"), 
    jobFile: Yup.mixed().required("Please select resume"), 
    // jobFile: Yup.mixed().required("Please select resume").test('fileFormat','Only PDF files are allowed', value => {
    //     if(value) {
    //         const supportedFormats = ['pdf'];
    //         return supportedFormats.includes(value.name.split('.').pop()) > -1;
    //     }
    // }).test('fileSize', 'File size must be less than 3MB', value => {
    //     if (value) {
    //       return value.size <= 3145728;
    //     }
    //     return true;
    //   })

    // jobFile: Yup.mixed().required("Please select resume").test("is-valid-type", "Not a valid pdf type",
    //   value => isValidFileType(value && value.name, "pdf"))
    // .test("is-valid-size", "Max allowed size is 100KB",
    //   value => value && value.size <= MAX_FILE_SIZE)
});