import React from "react";
import Map from "../components/page-wise-components/contact-us/Map.jsx";
import ContactForm from "../components/page-wise-components/contact-us/ContactForm.jsx";
import axios from "axios";
import { useQuery } from "react-query";
import { Helmet } from "react-helmet";
import { SITETITLE } from "../constants.js";

const getMetaData = async () => {
  const response = await axios.get(window.apiBaseUrl+'contact-us/meta-details');  
  return response.data.data;
};

const ContactUs = () => {
  const { data: meta, error, isLoading } = useQuery("noticeData", getMetaData);    
  const metaData = meta;

  if (isLoading) return <div>Fetching posts...</div>;
  if (error) return <div>An error occurred: {error.message}</div>;

  return (
    <>
      <Helmet>          
          <title>{metaData?(metaData.metaTitle):SITETITLE}</title>          
          <meta name='description' content={metaData?(metaData.metaDescription):SITETITLE} />
          <meta name='keywords' content={metaData?(metaData.metaKeywords):SITETITLE} />
      </Helmet>
      <Map />
      <ContactForm />
    </>
  );
};
export default ContactUs;
