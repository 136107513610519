import { useEffect, useState } from "react";
import Button from "react-bootstrap/Button";
import Form from "react-bootstrap/Form";
import Modal from "react-bootstrap/Modal";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import { useFormik } from "formik";
import { forApplyJobPost } from "../../../config/formValidation";
import axios from "axios";
import { toast } from "react-toastify";

function JobApplyModal({ showModal, onClose, jobTitle, jobId}) {
  const [show, setShow] = useState(false);

  const initialValues = {
    jobId: jobId,    
    designation: jobTitle,    
    fullName:"",
    emailId:"",
    mobileNumber:"",
    qualification:"",
    stateId:"",
    cityId:"",
    jobFile:"",
  }

  useEffect(() => {
    // Show or hide the modal based on the showModal prop
    setShow(showModal);
  }, [showModal]);

  const handleClose = () => {
    setShow(false);
    if (onClose) {
      onClose();
    }
  };

  const {values, errors, dirty, isValid, isSubmitting, touched, handleBlur, handleChange, handleSubmit} = useFormik({
    initialValues: initialValues,
    validationSchema: forApplyJobPost,    
    onSubmit: async (values, action) => {      
      await axios.post(window.apiBaseUrl+'/career/apply-for-job', values, {
        headers: { "Content-Type": "multipart/form-data"}
      })
      .then((res) => {
        if(res.status === 200){                
          toast.success(res.data.message, {          
            autoClose: 5000,          
            theme: "colored",
          }); 
          action.resetForm();       
        } else {
          toast.warn(res.data.message, {          
            autoClose: 5000,          
            theme: "colored",
          });
        }
      })
      .catch((err) => {      
        toast.error(err.response.data.message, {        
          autoClose: 5000,        
          theme: "colored",
          });
      }); 
    }
  });

  console.log(errors);
  
  return (
    <>
      <Modal show={show} onHide={handleClose}>
        <Modal.Header closeButton>
          <Modal.Title>Job Apply</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <Form encType="multipart/form-data" noValidate onSubmit={handleSubmit}>
          <Form.Group
              className="mb-3"
              controlId="exampleForm.ControlInput1"
            >              
              <Form.Control
                type="hidden"
                name="jobId"
                id="jobId"
                value={values.jobId}
                onChange={handleChange}
                onBlur={handleBlur}
                placeholder="Job ID"                    
                size="sm"
                disabled
              />
              <Form.Control.Feedback type="invalid">
                {errors.jobId}
              </Form.Control.Feedback>
            </Form.Group>
            <Form.Group
              className="mb-3"
              controlId="exampleForm.ControlInput1"
            >
              <Form.Label>Designation</Form.Label>
              <Form.Control
                type="text"
                name="designation"
                id="designation"
                value={values.designation}
                onChange={handleChange}
                onBlur={handleBlur}
                placeholder="Job title"                    
                size="sm"
                disabled
              />
              <Form.Control.Feedback type="invalid">
                {errors.designation}
              </Form.Control.Feedback>
            </Form.Group>
            <Form.Group className="mb-3" controlId="validationFormik01" htmlFor="fullName">
              <Form.Label htmlFor="fullName">Full Name</Form.Label>
              <Form.Control
                type="text"
                name="fullName"
                id="fullName"
                value={values.fullName}
                onChange={handleChange}
                onBlur={handleBlur}
                isInvalid={!!errors.fullName}
                size="sm"
                autoFocus
              />
              <Form.Control.Feedback type="invalid">
                {errors.fullName}
              </Form.Control.Feedback>
            </Form.Group>
            <Form.Group className="mb-3" controlId="validationFormik03">
              <Form.Label>Email ID</Form.Label>
              <Form.Control
                type="email"
                name="emailId"
                id="emailId"
                value={values.emailId}
                onChange={handleChange}
                onBlur={handleBlur}
                isInvalid={touched.emailId && !!errors.emailId}
                size="sm"
              />
              <Form.Control.Feedback type="invalid">
                {errors.emailId}
              </Form.Control.Feedback>
            </Form.Group>
            <Form.Group
              className="mb-3"
              controlId="exampleForm.ControlInput2"
            >
              <Form.Label>Contact No.</Form.Label>
              <Form.Control
                type="phone"
                name="mobileNumber"
                id="mobileNumber"
                maxLength={10}
                value={values.mobileNumber}
                onChange={handleChange}
                onBlur={handleBlur}
                isInvalid={touched.mobileNumber && !!errors.mobileNumber}
                size="sm"
              />
              <Form.Control.Feedback type="invalid">
                {errors.mobileNumber}
              </Form.Control.Feedback>
            </Form.Group>
            <Form.Group
              className="mb-3"
              controlId="exampleForm.ControlInput3"
            >
              <Form.Label>Qualification</Form.Label>
              <Form.Control
                type="text"
                name="qualification"
                id="qualification"
                value={values.qualification}
                onChange={handleChange}
                onBlur={handleBlur}
                isInvalid={!!errors.qualification}
                size="sm"
              />
              <Form.Control.Feedback type="invalid">
                {errors.qualification}
              </Form.Control.Feedback>
            </Form.Group>
            <Row className="mb-3">
              <Form.Group as={Col} controlId="formGridState">
                <Form.Label>Select State</Form.Label>
                <Form.Select                  
                  name="stateId"
                  id="stateId"
                  value={values.stateId}
                  onChange={handleChange}
                  onBlur={handleBlur}
                  isInvalid={!!errors.stateId}
                >
                  <option defaultValue="">Choose...</option>
                  <option defaultValue="madhya">Madhya Pradesh</option>
                  <option defaultValue="maharashtra">Maharashtra</option>
                </Form.Select>
                <Form.Control.Feedback type="invalid">
                  {errors.stateId}
                </Form.Control.Feedback>
              </Form.Group>
              <Form.Group as={Col} controlId="formGridCity">
                <Form.Label>Select District</Form.Label>
                <Form.Select                  
                  name="cityId"
                  id="cityId"
                  value={values.cityId}
                  onChange={handleChange}
                  onBlur={handleBlur}
                  isInvalid={!!errors.cityId}
                >
                  <option defaultValue="">Choose...</option>
                  <option defaultValue="madhya">Madhya Pradesh</option>
                  <option defaultValue="maharashtra">Maharashtra</option>
                </Form.Select>
                <Form.Control.Feedback type="invalid">
                  {errors.cityId}
                </Form.Control.Feedback>
              </Form.Group>
            </Row>
            <Form.Group controlId="jobFile" className="mb-3">
              <Form.Label>Upload Resume</Form.Label>
              <Form.Control
                type="file"
                name="jobFile"
                id="jobFile"
                value={values.jobFile}
                onChange={handleChange}
                onBlur={handleBlur}
                isInvalid={!!errors.jobFile}
                size="sm"
                accept='.pdf'
              />
              <Form.Control.Feedback type="invalid">
                {errors.jobFile}
              </Form.Control.Feedback>
            </Form.Group>
            <Modal.Footer>
              <Button variant="secondary" onClick={handleClose}>
                Cancel
              </Button>
              <Button variant="primary" type="submit" disabled={!(dirty && isValid && !isSubmitting)}>
              {isSubmitting? 'Please wait...': 'Apply'}
              </Button>
            </Modal.Footer>
          </Form>          
        </Modal.Body>
      </Modal>
    </>
  );
}

export default JobApplyModal;
