import React from 'react'

const VideoComponent = (props) => {
  return (
    <>
    <section className="course_single_video">
        <div className="container">
          <h2>Animated Demo Videos</h2>
          <div className="row">
            {props.data?(props.data.map((videos, index) => (
              <div key={index} className="col-md-3">
                <div className="item">
                  <div className="single-course flex-fill">
                    <div className="courses-image">
                      <div className="course-tag">
                        <p>{videos.categoryName} </p>
                      </div>
                      <a href="/" className="">
                        <img
                          src={videos.thumbnail}
                          alt={videos.categoryName}
                          loading="lazy"
                        />
                      </a>
                      <button
                        type="button"
                        className="btn btn-primary video-btn"
                        style={{
                          backgroundColor: "#b40f23",
                          borderRadius: "9px",
                        }}
                      >
                        <a
                          style={{ color: "white", backgroundColor: "#b40f23" }}
                          href={videos.link}
                          className="play videoLightbox courseVideoLightbox"
                          data-id="1679091c5a880faf6fb5e6087eb1b2dc"
                        >
                          <i className="fa fa-play"></i>
                        </a>
                      </button>
                    </div>
                    <div className="courses-content">
                      <div className="top-meta">
                        <a className="tag" href="/">
                          {videos.subjectName}
                        </a>
                        <span className="price">
                          <span className="sale-price">Rs. {videos.price}</span>
                        </span>
                      </div>
                      <p className="author-name">{videos.departmentName} </p>
                      <span className="course-name-tag">{videos.courseName} </span>
                    </div>
                    <div className="courses-meta">
                      <div className="bg-primary view-more-btn text-white text-center py-2">
                        <a
                          href="http://localhost/user-development/digital-plan-filter?type=subject-wise-package"
                          className="add-cart-btn view-more-button-text"
                        >
                          View more
                        </a>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            ))):''}
          </div>
        </div>
      </section>
    </>
  );
};

export default VideoComponent
