import axios from "axios";
import React, { useEffect, useState } from "react";
import { Accordion } from "react-bootstrap";
import { Link } from "react-router-dom";
import { toast } from "react-toastify";
import { useCart } from "react-use-cart";
import PromoCodeModal from "../components/core/cart/PromoCodeModal";

const Cart = () => {
  const { isEmpty, totalUniqueItems, items, cartTotal, removeItem } = useCart();  
  const [ recommend, setRecommended] = useState([]);
  const [ promoCodeList, setPromoCodeList] = useState([]);
  
  const [isModalVisible, setModalVisible] = useState(false);

  const handleButtonClick = () => {
    // Show the modal when the button is clicked
    setModalVisible(true);
  };

  useEffect(()=> {
    getPromoCodeData();
    if(!isEmpty){      
      const para = {
        "subjectId" : items[0].id
      }
      getRecommendedData(para);
    }
  },[isEmpty]);

  // const formData = new FormData();
  // formData.append("subjectId", subjectId);

  // console.log("para",para);

  const getRecommendedData = async (para) => {
    await axios.post(window.apiBaseUrl+'cart/recommended-subject', para, {
      headers: { "Content-Type": "multipart/form-data" }
    })
    .then((res) => {
      if(res.status === 200){
        setRecommended(res.data.data);        
      } else {
        console.log(res.data.message);
      }
    })
    .catch((err) => {      
      console.log(err.response.data.message);
    }); 
  };

  const getPromoCodeData = async (para) => {
    await axios.get(window.apiBaseUrl+'cart/promo-codes')
    .then((res) => {
      if(res.status === 200){
        setPromoCodeList(res.data.data);        
      } else {
        console.log(res.data.message);
      }
    })
    .catch((err) => {      
      console.log(err.response.data.message);
    }); 
  };
  
  return (
    <>
      <div
        className="section page-banner-section bg-color-1 cart-banner"
        style={{
          backgroundImage: `url(
            "https://www.matriyeacademy.com/website-assets/images/cart-banner.png"
          )`,
        }}
      >
        <div className="container">
          {isModalVisible && (
            <PromoCodeModal
              showModal={isModalVisible}
              onClose={() => setModalVisible(false)}
              promoCodesData={promoCodeList}
            />
          )}
          <div className="course-details-banner-content shopping-cart">
            <h1 className="title">
              <p>
                Matriye Academy is chosen by top organizations and institutions
                to help them develop in-demand career skills.
              </p>
              Shopping Cart
            </h1>
          </div>
        </div>
      </div>
      {isEmpty?(
        <>
          <section class="cart-empty">
            <div class="container h-50">
                <div class="cart-empty-inner">
                    <h5>Your cart is empty</h5>
                    <Link to={"/filter"} class="cont_shop">Explore our courses</Link>
                </div>
            </div>
          </section>
        </>):(
        <>
          <section className="cart-section">
            <div className="container">
              <div className="row">
                <h2 className="cart-head">Order Summary</h2>
                <div className="col-md-7">
                  <div className="summary-section">
                    
                    {items.map((item,index) => (
                      <div key={index} className="summary-outer">
                        <div className="summary-left">
                          <div className="summary-left-one">
                            <div className="summary-left-img">
                              <img
                                src={item.image}
                                alt={item.subjectName}
                              />
                            </div>
                            <div className="summary-left-content">
                              <h4>{item.subjectName}</h4>
                              <p>{item.courseName}</p>
                            </div>
                          </div>
                        </div>
                        <div className="summary-right">
                          <Link 
                            variant="danger" onClick={()=> removeItem(item.id)}
                            className="text-danger"
                          >
                            <i className="fa fa-trash close-summary text-danger"></i>
                          </Link>
                          <h5 className="summary-price">
                            <i className="fa fa-inr"></i>{item.price}
                          </h5>
                          {/* <p className="summary-cut">
                            <i className="fa fa-inr"></i>
                          </p> */}
                        </div>
                      </div>
                    ))}

                    <div className="recommend mt-3">
                    {recommend?(
                    <Accordion defaultActiveKey="0">                  
                      <Accordion.Item eventKey={0}>
                        <Accordion.Header>Recommended For You</Accordion.Header>
                        <Accordion.Body>
                        {recommend.map((item, index) => (
                          <div key={index+1} className="recommend-head">
                            <div className="summary-left">
                              <div className="summary-left-one">
                                <div className="summary-left-img">
                                  <img
                                    src={item.subjectPicture}
                                    alt={item.subjectName}
                                    loading="lazy"
                                  />
                                </div>
                                <div className="summary-left-content">
                                  <h4>
                                    <Link to={`/courses/${item.courseNameSlugUrl}/${item.subjectNameSlugUrl}`}>
                                      {item.subjectName}
                                    </Link>
                                  </h4>
                                  <p className="price-recommend">
                                    <i className="fa fa-inr"></i>{item.subjectPrice}
                                    {/* <span>
                                      <i className="fa fa-inr"></i>0.00
                                    </span> */}
                                  </p>
                                </div>
                              </div>
                              <div className="recommend-list">{item.subjectLongDescription}</div>
                              <hr />
                            </div>
                            <div className="get-btn">
                              <Link to={`/courses/${item.courseNameSlugUrl}/${item.subjectNameSlugUrl}`} className="get-btn-btn">
                                Get It
                              </Link>
                            </div>
                          </div>
                        ))}
                        </Accordion.Body>
                      </Accordion.Item>
                    </Accordion> 
                    ):""}                      
                    </div>
                  </div>
                </div>
                <div className="col-md-5">
                  <div className="subtotal-section">
                    <h3>Order Summary</h3>
                    <p>{totalUniqueItems} items</p>
                    <div className="subtotal-price">
                      <h4>Total MRP</h4>
                      <p>
                        <i className="fa fa-inr"></i>
                        <span id="totalMRP">{cartTotal}</span>
                      </p>
                    </div>

                    {false?
                    <>
                    <div className="subtotal-price">
                      <h4>Discounted Coupon</h4>
                      <p>
                        <i className="fa fa-inr"></i>
                        <span id="discountedCouponPrice">0</span>
                      </p>
                    </div>
                    </>:''}

                    <hr />
                    <div className="subtotal-price">
                      <h4>Total Amount (INR)</h4>
                      <p>
                        <i className="fa fa-inr"></i>
                        <span id="totalAmount">{cartTotal}</span>
                      </p>
                    </div>
                    <div className="subtotal-description">
                      <p>Total amount does not include applicable taxes</p>
                      <form>
                        <div className="d-flex justify-content-center gap-3" >
                          <input style={{width: "60%", fontSize: "14px", padding: "0 5px"}} placeholder="Promo code" type="text" name="" id="" />
                          <button className="ready-btn text-white my-0" style={{fontSize: "13px", padding: "4px 16px"}}>Apply</button>
                        </div>
                      </form>
                      <p>
                        Don't Have a Promo Code? <span onClick={handleButtonClick} style={{ cursor: "pointer" }} className="fw-bold">Click here</span>
                      </p>
                      <p>
                        {false?<>
                          <i className="fa fa-check"></i>{" "}
                        <span>VALID PROMO CODE.</span>
                        </>:''} You're getting the best price
                        we have got
                      </p>
                      <Link to="/checkout" className="ready-btn text-white">
                        I'm Ready to pay
                      </Link>
                      <p>Nice!</p>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </section>
        </>
      )}
    </>
  );
};

export default Cart;
