import React from "react";

const AboutUsInfoBlock = (props) => {
  return (
    <>
      <section className="about-info" id="about-infos">
        <div className="container" id={props.customId}>
          <div className="wrapper-info-about">
            <div className="row align-items-center">
              <div className="col-md-5">
                <img
                  src={props.data.aboutImage}
                  alt="Welcome To Matriye Academy"
                />
              </div>
              <div className="col-md-7">
                <h3>{props.data.aboutHeading}</h3>
                  <div dangerouslySetInnerHTML={{ __html: props.data.aboutParagraphOne }}></div>                
              </div>
              <div className="col-md-12 mt-4">
                <div dangerouslySetInnerHTML={{ __html: props.data.aboutParagraphTwo }}></div>
              </div>
            </div>
          </div>
        </div>
      </section>
    </>
  );
};

export default AboutUsInfoBlock;
