import React from "react";
import BlogHeaderBanner from "../../components/page-wise-components/blog/BlogHeaderBanner";
import BlogListBody from "../../components/page-wise-components/blog/BlogListBody.jsx";
import { Helmet } from "react-helmet";

const Blogs = () => {

  return (
    <>
      <Helmet>          
          <title>Blogs</title>          
          <meta name='description' content="" />
          <meta name='keywords' content="" />
      </Helmet>
      <BlogHeaderBanner />      
      <BlogListBody />      
    </>
  );
};
export default Blogs;
