import React from 'react'
import { Link } from 'react-router-dom';

const PackageComponent = (props) => {
  return (
    <>
    <div className="container">
    {props.data?(
        <div className="row justify-content-between py-5">
          {props.data.map((pacakges, index) => (
            <div key={index} className="col-lg-4 order-lg-2 mt-5">
              <div className="sidebar-details-wrap">
                <div className="sidebar-details-video-description">
                  <div className="sidebar-video">
                    <img
                      src={pacakges.planImage}
                      alt="video-thumbnail"
                      style={{ height: "210px", width: "100%" }}
                    />
                  </div>
                  <div className="sidebar-description">
                    <div className="price">
                      <span className="sale-price">{pacakges.planName}</span>
                    </div>
                    {pacakges.features?(
                    <ul className="description-list">
                        {pacakges.features.map((item, index) => (
                        <li key={index}>
                            <i className="fa fa-check"></i> {item.featureName}
                            <span>{item.featureDescription} </span>
                        </li>
                        ))}                      
                    </ul>
                    ):''}
                    <div className="courses-btn">
                      <Link
                        to="/digital-plan-filter"
                        className="btn btn-primary btn-hover-heading-color"
                      >
                        View Details
                      </Link>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          ))}
        </div>
    ):''}
      </div>
    </>
  );
};

export default PackageComponent
