import React, { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import axios from "axios";
import { toast } from "react-toastify";
import { useQuery } from "react-query";

const getBlogCategoryData = async () => {
  const response = await axios.get(window.apiBaseUrl+'blog/category-list');  
  return response.data.data;
};

const getTagData = async () => {
  const response = await axios.get(window.apiBaseUrl+'blog/tags-list');  
  return response.data.data;
};

const BlogListBody = () => {  
  const { data: categoryList, error, isLoading } = useQuery("categoryData", getBlogCategoryData);    
  const { data: tagList } = useQuery("tagData", getTagData);    
  const categoryListData = categoryList;
  const tagListData = tagList;
  const [blogListData, setBlogList] = useState([]);

  useEffect(()=>{
    getBlogListData();
  },[]);

  const formData = new FormData();
  formData.append("limit", 10);
  formData.append("offset", 0);

  const getBlogListData = async () => {
    await axios.post(window.apiBaseUrl+'/blog/blog-list', formData, {
      headers: { "Content-Type": "multipart/form-data" }
    })
    .then((res) => {
      if(res.status === 200){
        setBlogList(res.data.data);
      } else {
        toast.warn(res.data.message, {          
          autoClose: 5000,          
          theme: "colored",
        });
      }
    })
    .catch((err) => {      
      toast.error(err.response.data.message, {        
        autoClose: 5000,        
        theme: "colored",
        });
    }); 
  };
  
  if (isLoading) return <div>Fetching record...</div>;
  if (error) return <div>An error occurred: {error.message}</div>;

  return (
    <>
      <div className="section section-padding">
        <div className="container">
          <div className="blog-wrapper-02">
            <div className="row justify-content-between">

              {blogListData.blogList?(
              <div className="col-lg-8">                
                {blogListData.blogList.map((blog, index) => (
                <div key={index} className="single-blog blog-list">
                  <div className="blog-image">
                    <Link to={`/blogs/${blog.blogCategoryNameSlugUrl}/${blog.blogTitleSlugURL}`}>
                      <img loading="lazy" src={blog.blogImage} alt={blog.authorName} />
                    </Link>
                    <span className="tags">{blog.blogCategoryName}</span>
                  </div>
                  <div className="blog-content">
                    <div className="meta">
                      <a href="/">
                        <i className="fa fa-user-o"></i>
                        {blog.authorName}
                      </a>
                      <a href="/">
                        <i className="fa fa-calendar-o"></i>
                        {blog.dateOfBlog}
                      </a>
                    </div>
                    <h2 className="title">
                      <Link to={`/blogs/${blog.blogCategoryNameSlugUrl}/${blog.blogTitleSlugURL}`}>{blog.blogTitle}</Link>
                    </h2>                    
                    <div dangerouslySetInnerHTML={{ __html: blog.blogDescriptionOne }}></div>
                    <Link
                      to={`/blogs/${blog.blogCategoryNameSlugUrl}/${blog.blogTitleSlugURL}`}
                      className="btn btn-primary btn-hover-heading-color"
                    >
                      Read More
                    </Link>
                  </div>
                </div>
                ))}
              </div>
              ):''}

              <div className="col-xl-3 col-lg-4">
                <div className="sidebar-wrap">
                  <div className="sidebar-widget widget-search-desktop">
                    <div className="widget-search">
                      <form
                        action="http://localhost/matriyeacademy/blog?"
                        autoComplete="off"
                        method="get"
                        acceptCharset="utf-8"
                      >
                        <input
                          className="form-control"
                          type="text"
                          name="search-blog"
                          id="search-blog"
                          placeholder="Search for blog name, tags"
                          required=""
                        />
                        <button type="submit">
                          <i className="fa fa-search"></i>
                        </button>
                      </form>
                    </div>
                  </div>
                  <div className="sidebar-widget sidebar-widget-about">
                    <h3>About us</h3>
                    <div className="textwidget">
                      <p>
                        Matriye EdTech offers career-relevant online E-learning
                        programs the Academic, Professional Courses, and
                        Competitive exams.
                      </p>
                      <h5 className="name">Social Media</h5>
                      <ul className="social-box">
                        <li className="twitter">
                          <a
                            href="https://twitter.com/MatriyeA"
                            className="fa fa-twitter"
                          >
                            {" "}
                          </a>
                        </li>
                        <li className="linkedin">
                          <a
                            href="https://www.linkedin.com/company/matriye-academy/"
                            className="fa fa-linkedin"
                          >
                            {" "}
                          </a>
                        </li>
                        <li className="facebook">
                          <a
                            href="https://www.facebook.com/Matriye-Academy-537784870484442"
                            className="fa fa-facebook-f"
                          >
                            {" "}
                          </a>
                        </li>
                        <li className="instagram">
                          <a
                            href="https://www.instagram.com/matriyeacademy/"
                            className="fa fa-instagram"
                          >
                            {" "}
                          </a>
                        </li>
                      </ul>
                    </div>
                  </div>

                  {blogListData.blogList?(
                  <div className="sidebar-widget">
                    <h3 className="widget-title">Popular Posts</h3>
                    <div className="widget-post">
                      {blogListData.blogList.slice(0,3).map((blogs,index)=>(
                      <div key={index} className="single-mini-post">
                        <div className="mini-post-image">
                          <Link to={`/blogs/${blogs.blogCategoryNameSlugUrl}/${blogs.blogTitleSlugURL}`}>
                            <img
                              loading="lazy"
                              src={blogs.blogImage}
                              alt={blogs.blogId}
                            />
                          </Link>
                        </div>
                        <div className="mini-post-content">
                          <h5 className="title">
                            <Link to={`/blogs/${blogs.blogCategoryNameSlugUrl}/${blogs.blogTitleSlugURL}`}>{blogs.blogTitle}</Link>
                          </h5>
                          <span className="date">
                            <i className="fa fa-calendar"></i>
                            &nbsp;{blogs.dateOfBlog}
                          </span>
                        </div>                        
                      </div>
                      ))}
                    </div>
                  </div>
                  ):''}

                  {categoryListData?(
                  <div className="sidebar-widget">
                    <h3 className="widget-title">Categories</h3>
                    <div className="widget-sidebar-link">
                      <ul>
                      {categoryListData.map((category, index) => (
                        <li key={index}>
                          <a href="/">
                          {category.blogCategoryName} <span>{category.blogCount}</span>
                          </a>
                        </li>
                        ))}
                      </ul>
                    </div>
                  </div>
                  ):''}

                  {tagListData?(
                  <div className="sidebar-widget">
                    <h3 className="widget-title">Tags</h3>
                    <div className="widget-tags">
                      <ul>
                        {tagListData.map((tags, index) => (
                        <li key={index}>
                          <a href="/">{tags.tagsName}</a>
                        </li>
                        ))}
                      </ul>
                    </div>
                  </div>
                  ):''}
                  
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default BlogListBody;
