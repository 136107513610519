import React, { useState } from "react";
import { Link } from "react-router-dom";

const CareerQualitySection = (props) => {
  
  const tabData = props.data;

  const [selectedOption, setSelectedOption] = useState(tabData[0].advantageId);

  const handleTabClick = (option) => {
    setSelectedOption(option);
  };

  return (
    <>
      <section className="quality-section">
        <div className="container">
          <div className="row">
            <div className="col-md-3">
              <ul className="nav nav-tabs">
                {tabData.map((tab) => (
                  <li key={tab.advantageId}>
                    <a
                      id="tab"
                      onClick={() => handleTabClick(tab.advantageId)}
                      className={selectedOption === tab.advantageId ? "active show" : ""}
                    >
                      {tab.advantageName}
                    </a>
                  </li>
                ))}
              </ul>
            </div>
            <div className="col-md-9">
              <div className="tab-content">
                <div
                  id={selectedOption}
                  className={`tab-pane fade in active show`}
                >
                  <h3>
                    {tabData.find((tab) => tab.advantageId === selectedOption)?.advantageName}
                  </h3>
                  <p>
                    {tabData.find((tab) => tab.advantageId === selectedOption)?.advantageDescription}
                  </p>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
    </>
  );
};

export default CareerQualitySection;
